// affiche l'ensemble des produits
import { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import UseFetch from '../components/UseFetch';
import UseModal from '../components/UseModal';
import Breadcrumb from '../components/Breadcrumb';
import Loader from '../components/Loader';
import pot_printemps from '../assets/printemps.jpg';
import pot_tilleul from '../assets/tilleul.jpg';
import pot_ete from '../assets/ete.jpg';
import '../style/Products.css';

function Product({ cart, updateCart }) {
    const [isModal, setIsModal] = useState(false);

    function addToCart(id, title, price, price_id) {
        const ArticleAlreadyThere = cart.find((article) => article.id === id);
        if (ArticleAlreadyThere) {
            const cartFilteredCurrentArticle = cart.filter((article) => article.id !== id);
            updateCart([...cartFilteredCurrentArticle, { id, title, price, price_id, quantity: ArticleAlreadyThere.quantity + 1 }]);
        } else {
            updateCart([...cart, { id, title, price, price_id, quantity: 1 }]);
        }
	}  

    const { apiData, isFetched, isError } = UseFetch(`${process.env.REACT_APP_SERVER_URL}/api/article`)
   
    return (
        <>
        <Breadcrumb 
        dest_name1='Miels'
        style_link1={{fontWeight: 'bold'}}
        gt1={{display: 'none'}}
        gt2={{display: 'none'}}
        />
        <div id='products_container' className='g_layout' style={isFetched === true ? {paddingBottom: '60px'} : {paddingBottom: '450px'}}>
            <div className={isModal ? 'layer_modal g_layout' : 'hidden_modal'}>
                <div className='product_h1_block'>
                    <h1 className='parisienne h1_parisienne'>Miels du Béarn</h1>
                </div>
                {isError === true ? <div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div> : 
                isFetched === false ? <Loader /> : apiData.map((product) => {
                    return (
                        <div key={product._id} className='product_card_container'>
                            <div className='product_card'>
                                <div className='card_block'>
                                    <Link  to={`/products/article/${product._id}#article_container`} className='product_link'>
                                        <img 
                                        src={
                                            product._id === '647ef364573064f42bf0c789' ? pot_printemps :
                                            product._id === '64b6aeb8e8fa63aded29eed6' ? pot_tilleul : pot_ete  
                                        } 
                                        alt="pot de miel" 
                                        className='img_def' 
                                        />
                                    </Link>
                                </div>
                                <div className='product_title'>
                                    <h2 className='product_h2'>{product.title}</h2>
                                </div>
                                <div className='product_buying'>
                                    <div className='product_price'>{`${product.price}€`}</div>
                                    {product.stock <1 ? 
                                        <p style={{color: 'red', marginTop: '0px'}}>Hors-stock</p> : 
                                        <button 
                                            className='product_add_cart'
                                            aria-label='ajouter au panier' 
                                            onClick={() => {
                                                addToCart(product._id, product.title, product.price, product.price_id); 
                                                setIsModal(!isModal)
                                            }}
                                        >
                                            <i className="fa-solid fa-basket-shopping "></i>
                                        </button>
                                    }
                                </div>
                                <div className='separate_block'>
                                    <div className='separate'></div>
                                </div>
                            </div>
                        </div>
                    )
                })} 
            </div>
        </div>
        <UseModal 
        isModal={isModal}
        setIsModal={setIsModal} 
        style_second_btn={{display: 'none'}}
        option1_btn={'Ok'} 
        option2_btn={''} 
        modal_text={'produit ajouté au panier'}
        handleClick={() => setIsModal(!isModal)}
        />
        </>
    ) 
}

export default Product