// relie chaque commande terminée à sa facture PDF, importé sur les pages UserAccount et Bill
import UseFetch from './UseFetch';
import Loader from './Loader';
import '../style/Bill.css';

const pdfFolder = require.context('../assets/pdf', true);	// importe le dossier contenant les factures pdf
const pdfList = pdfFolder.keys().map(pdf => pdfFolder(pdf)); // retourne un tableau contenant tous les pdf(strings)

function BillComp() {
	const { apiData, isFetched, isError } = UseFetch(`${process.env.REACT_APP_SERVER_URL}/api/order/get`);
	// retourne un tableau des 12 commandes terminées les plus récentes
	const lastOrdersConcluded = !apiData ? '' : apiData.filter((order) => order.status === "terminé").slice(-12).reverse();

	function showPdf(invoiceId) {
		const pdf = pdfList.filter((element) => element.includes(invoiceId)); // retourne la facture pdf correspondant à la commande
		pdf.length === 0 ? alert("la facture n'est pas encore éditée") : window.open(pdf[0]);
	}

	if(isError === true)  {
		return<div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div>
	} else {
		return (
			<div id="bill_container" className='g_layout' >
				<h1 className="bill_h1">Mes factures</h1>
				{isFetched === false ? <Loader /> :
					(apiData <1 && isFetched === true) || lastOrdersConcluded.length === 0 ? 
					<div className='no_account' style={{marginTop: '50px', color: 'white'}}>Vous n'avez pas de commandes terminées</div> :
					<div className='bill_content'>
						{lastOrdersConcluded.map((order) => {
							return (
								<div className='bill_block' key={order._id}>
									<button className='bill_button' onClick={() => showPdf(order.invoiceId)}>
										<div className='bill_title'>
											<i className="fa-regular fa-file bill_icon"></i>
											<div className='bill_number_date'>
												Commande n°{order.invoiceId} du {new Date(order.createdAt).toLocaleDateString()}
											</div>
										</div>
									</button>
								</div>	
							)
						})}
					</div>
				}
			</div>
		)
	}
}	

export default BillComp