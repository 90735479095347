// Ajoute le produit sélectionné au panier, importé sur la page Cart
import { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import pot_printemps from '../assets/printemps.jpg';
import pot_tilleul from '../assets/tilleul.jpg';
import pot_ete from '../assets/ete.jpg';
import '../style/AddArticle.css';

function AddArticle(props) {
    const [inputValue, setInputValue] = useState(1);
    const [isModal, setIsModal] = useState(false);  // modal récapitulatif du panier

    const inputValueAsNumber = Number(inputValue);
    const quantities = props.cart.length === 0 ? null : props.cart.map((article => article.quantity)).reduce((a, b) => a+b);
    const total = props.cart.length === 0 ? null : props.cart.map((article => article.quantity*article.price)).reduce((a, b) => a+b);

    function addToCart(id, title, price, price_id) {
        const ArticleAlreadyThere = props.cart.find((article) => article.id === id);
        if(ArticleAlreadyThere) {
            const cartFilteredCurrentArticle = props.cart.filter((article) => article.id !== id)
            props.updateCart([
                ...cartFilteredCurrentArticle, { 
                    id, title, price, price_id, quantity: ArticleAlreadyThere.quantity + inputValue 
                }
            ])
        } else {
            props.updateCart([...props.cart, { id, title, price, price_id, quantity: inputValue }])
        }
	}  

    return (
        <>
        <div className={`addCart_container addCart_cont_art ${isModal ? "showModal" : "hideModal"}`}>
            <div className='quantity_choice'>
                {(inputValue <= 1 || inputValueAsNumber <= 1) ? 
                <button disabled className='minus'>-</button> : 
                <button className='minus' onClick={() => setInputValue(inputValueAsNumber -1)}>-</button>}
                <label htmlFor="quantity">
                    <input
                    className="article_quantity_input"
                    id="quantity"
                    type="number"
                    name='article_quantity_input'
                    aria-label="quantité"
                    onChange={(e) => setInputValue( + e.target.value)} 
                    value={inputValue}
                    />
                </label>
                <button className='plus' onClick={() => setInputValue(inputValueAsNumber +1)}>+</button>
            </div>
            <button 
            className='button_addCart btn_ad_article' 
            onClick= {() => {addToCart(props.id, props.title, props.price, props.price_id); setIsModal(!isModal)}}
            >
                Ajouter au panier
            </button>
            <div className="modal_article">
                <div className="modal_description">
                    <i className="fa-solid fa-check"></i>
                    <p>produit ajouté au panier</p>
                </div>
                <div className="modal_card_container">
                    <div className="modal_card_block">
                    <img 
                    src={
                        props.id === '647ef364573064f42bf0c789' ? pot_printemps :
                        props.id === '64b6aeb8e8fa63aded29eed6' ? pot_tilleul : pot_ete  
                    } 
                    alt="pot de miel" 
                    className='img_def' 
                    />
                    </div>
                    <div className="modal_def">
                        <span>{props.title}</span>
                        <span>{props.price} €</span>
                        <span>Quantité : {inputValue}</span>
                    </div>
                    
                </div>
                <div className="modal_cart_info">
                    <p>Il ya {quantities} articles dans votre panier.</p>
                    <div className="modal_total" >
                        <p style={{fontWeight: "bold"}}>total :</p>
                        <p className="modal_euros">{total} €</p>
                    </div>
                </div>
                <Link to='/products#products_container'>
                    <button className="button_addCart btn2" onClick={() => setIsModal(!isModal)}>
                        Continuer mes achats
                    </button>
                </Link>
                <Link to='/cart#cart_container'>
                    <button className="button_addCart btn2">
                        Commander
                    </button>
                </Link>
            </div>
        </div>
        </>
    )
}

export default AddArticle