// affiche les adresses utilisateur(facturation, livraison) sur différentes pages et formats. 
// Composant des pages userAccount(desktop) ou Address(<991px)
import { HashLink as Link } from 'react-router-hash-link';
import { useState } from 'react';
import UseFetch from './UseFetch';
import Loader from './Loader';
import Toggle from './Toggle';
import AddressToggles from './AddressToggles';
import UserInfo from './UserInfo';
import '../style/Address.css';

function AddressComp() {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    
    const [isModal, setIsModal] = useState(false);
    
    const { apiData, isFetched, isError } = UseFetch(`${process.env.REACT_APP_SERVER_URL}/api/userData/getOne`);

    return (
        <div id='addressComp_container' className='g_layout'>
            {isError === true ? <div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div> :
                <>
                <h1 className='addressC_h1 h1_desk_fontSize'>Mes adresses</h1>
                {isFetched === false ? <Loader /> :
                    apiData === null && isFetched === true ? 
                    <p>Vous n'avez pas d'adresses enregistrées</p> :
                    <div className='addressC_profil_block'>
                        <Toggle 
                        isOpen={isOpen} 
                        setIsOpen={setIsOpen} 
                        handleClick={() => {isOpen === false ? 
                            setIsOpen(true) : setIsOpen(false);
                            setIsOpen1(false);
                            setIsOpen2(false);
                            setIsOpen3(false);
                        }} 
                        title={'Adresse principale'}
                        content={
                            <>
                            <UserInfo 
                            civility={apiData.civility}
                            firstName={apiData.firstName} 
                            lastName={apiData.lastName}
                            address={apiData.address} 
                            additional={apiData.additional}
                            postCode={apiData.postCode} 
                            city={apiData.city} 
                            />
                            </>
                        }
                        />
                        <AddressToggles 
                        isOpen={isOpen}
                        isOpen1={isOpen1}
                        isOpen2={isOpen2}
                        isOpen3={isOpen3}
                        setIsOpen={setIsOpen}
                        setIsOpen1={setIsOpen1}
                        setIsOpen2={setIsOpen2}
                        setIsOpen3={setIsOpen3}
                        isModal={isModal} 
                        setIsModal={setIsModal}
                        /> 
                        <div className='addressC_link'>
                            <Link to='/user-account/registration/addressReg#addressReg_container'className='link_effect link_underline'>
                                Ajouter une adresse de livraison(max: 3)
                            </Link>
                        </div>
                    </div> 
                }
                </>
            }
        </div>
    )
}

export default AddressComp