// Affiche les modes de livraison sous forme de carte à cliquer. Composant de Delivery
// Affiche les adresses de livraison sous forme de carte à cliquer. Composant de DeliveryAddress
import { HashLink as Link } from 'react-router-hash-link';
import colissimo from '../assets/colissimo.jpg'
import '../style/DeliveryButton.css';

function DeliveryButton(props) {
    const {
        handleClick, 
        showIcon, 
        title, 
        price_text, 
        price, 
        isDeliveryBtn, 
        isAddressBtn,
        civility,
        firstName,
        lastName,
        address,
        additional,
        postCode,
        city,
        destLink
    } = props;

    return (
        isDeliveryBtn === true ?  // affichage de la carte sur Delivery
        <Link smooth to={destLink} className='deliveryB_link'>
            <button className='deliveryB_block_btn' onClick={() => handleClick()}>
                <div className='deliveryB_title'>
                    <div className='deliveryB_title_text'>
                        <p>{title}</p>
                    </div>
                    <div className='deliveryB_checkbox'>
                        <div className='deliveryB_icon'>
                            <i className="fa-solid fa-check" style={showIcon === true ? {display : 'flex'} : {display: 'none'}}></i>
                        </div>
                    </div>
                </div>
                <div className='deliveryB_logo'>
                    <img src={colissimo} alt="logo Colissimo" className='logo_colissimo'/>
                </div>
                <div className='deliveryB_price_block'>
                    <p className='deliveryB_price_text'>{price_text}</p>
                    <p className='deliveryB_price'>{price} €</p>
                </div>
            </button>
        </Link> : 
        isAddressBtn === true ?  // affichage de la carte sur DeliveryAddress
        <Link  to={destLink} className='deliveryB_link'>
            <button 
                className='deliveryB_block_btn' 
                onClick={() => handleClick()}>
                <div className='deliveryB_title'>
                    <div className='deliveryB_title_text'>
                        <p>{title}</p>
                    </div>
                    <div className='deliveryB_checkbox'>
                        <div className='deliveryB_icon'>
                            <i className="fa-solid fa-check" style={showIcon === true ? {display : 'flex'} : {display: 'none'}}></i>
                        </div>
                    </div>
                </div>
                <div className='deliveryB_info_block'>
                    <p className='deliveryB_field'>{civility} {firstName} {lastName}</p>
                    <p className='deliveryB_field'>{address}</p>
                    <p className='deliveryB_field'>{additional}</p>
                    <p className='deliveryB_field'>{postCode} {city}</p>
                </div>
            </button>
        </Link> : '' 
    )
}

export default DeliveryButton