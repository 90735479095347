//Pour choisir le mode de livraison(colissimo, point relais...) dans le tunnel de paiement. Composant de la page Checkout
import { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { homePrice, homeSignPrice, relayPrice } from './DeliveryPrice'; // fonction de calcul des frais de port
import DeliveryButton from './DeliveryButton';
import DeliveryRetirement from './DeliveryRetirement';
import { addStripeParams } from './AddStripeParams';
import '../style/Delivery.css';

function DeliveryMode(props) {
    const [showButton, setShowButton] = useState(false);  // bouton de validation des options de livraison choisies
    const [showIcon1, setShowIcon1] = useState(false); //icones de validation des cartes cliquées
    const [showIcon2, setShowIcon2] = useState(false);
    const [showIcon3, setShowIcon3] = useState(false);
    const [showIcon4, setShowIcon4] = useState(false);
    
    const [isDeliveryRetirement, setIsDeliveryRetirement] = useState(false);  // pour l'apparition du menu livraison point retrait
    const [isDeliveryRelay, setIsDeliveryRelay] = useState(false);  // pour l'apparition du menu livraison point relais
    
    const isDeliveryBtn = true;
    
    // logique d'affichage des frais de port
    const quantity = props.cart.map((element) => element.quantity).reduce((a, b) => a + b);
    const total = props.cart.length === 0 ? null : props.cart.map((article => article.quantity*article.price)).reduce((a, b) => a+b);
    const homeShipping = homePrice(quantity).toString().replace('.', ',');
    const signShipping = homeSignPrice(total, quantity).toString().replace('.', ',');
    const strangeSignShipping = Number(homeSignPrice(total, quantity).toFixed(2)).toString().replace('.', ',');
    const relayShipping = relayPrice(quantity).toString().replace('.', ',');

    function setChoice(title, price) {
        props.setDeliveryChoice({title: title, price: price});
        setShowButton(true);  
    }

    const openToggle = () => {
        props.setIsOpen1(false); 
        props.setIsOpen2(true);
    }

    return (
        <>
        <div id='delivery_container'>
            <DeliveryButton 
            handleClick={() => {
                setChoice('Livraison à domicile sans signature', homePrice(quantity));
                setIsDeliveryRelay(false);
                setIsDeliveryRetirement(false) 
                setShowIcon1(true);
                setShowIcon2(false);
                setShowIcon3(false);
                setShowIcon4(false);
            }} 
            showIcon={showIcon1} 
            title={'Domicile'} 
            price_text={'Livraison sans signature'} 
            price={homePrice(quantity) === 7.6 ? homeShipping + 0 : homeShipping}
            isDeliveryBtn={isDeliveryBtn}
            />
            <DeliveryButton 
            handleClick={() => {
                setChoice('Livraison à domicile contre signature', Number(homeSignPrice(total, quantity).toFixed(2)));
                setIsDeliveryRelay(false);
                setIsDeliveryRetirement(false) 
                setShowIcon1(false);
                setShowIcon2(true);
                setShowIcon3(false);
                setShowIcon4(false);
            }} 
            showIcon={showIcon2} 
            title={'Domicile'} 
            price_text={'Livraison contre signature'} 
            price={homePrice(quantity) === 7.6 ? signShipping + 0 :
                homePrice(quantity) === 9.55 ? strangeSignShipping : signShipping
            }
            isDeliveryBtn={isDeliveryBtn}
            />
            <DeliveryButton 
            handleClick={() => {
                setChoice('Livraison en point retrait', relayPrice(quantity)); 
                setIsDeliveryRetirement(true);
                setIsDeliveryRelay(false);
                setShowIcon1(false);
                setShowIcon2(false);
                setShowIcon3(true);
                setShowIcon4(false);
            }} 
            showIcon={showIcon3} 
            title={'Point retrait'} 
            price_text={'Livraison en bureau de poste'} 
            price={relayPrice(quantity) === 6.9 ? relayShipping + 0 : relayShipping}
            isDeliveryBtn={isDeliveryBtn}
            destLink='#delRet_container'
            />
            <DeliveryButton 
            handleClick={() => {
                setChoice('Livraison en point relais', relayPrice(quantity)); 
                setIsDeliveryRelay(true);
                setIsDeliveryRetirement(false)
                setShowIcon1(false);
                setShowIcon2(false);
                setShowIcon3(false);
                setShowIcon4(true);
            }} 
            showIcon={showIcon4} 
            title={'Point relais'} 
            price_text={'Livraison en relais Pickup'} 
            price={relayPrice(quantity) === 6.9 ? relayShipping + 0 : relayShipping}
            isDeliveryBtn={isDeliveryBtn}            
            destLink='#delRet_container'
            />
        </div> 
        {isDeliveryRetirement === true || isDeliveryRelay === true ?
            <DeliveryRetirement 
            isDeliveryRetirement={isDeliveryRetirement} 
            isDeliveryRelay={isDeliveryRelay}
            isAddress={props.isAddress} 
            setIsAddress={props.setIsAddress}
            deliveryPoint={props.deliveryPoint}
            setDeliveryPoint={props.setDeliveryPoint}
            isOpen1={props.isOpen1} 
            setIsOpen1={props.setIsOpen1}
            isOpen3={props.isOpen3}
            setIsOpen3={props.setIsOpen3}
            apiData={props.apiData}
            setApiData={props.setApiData}
            cart={props.cart}
            deliveryChoice={props.deliveryChoice}
            /> : <></>
        }
        <Link smooth to='#toggle_link_landmark' aria-label='Lien vers le tunnel de paiement'>
            <button 
            style={isDeliveryRetirement === true || isDeliveryRelay === true ? {display: 'none'} : {marginBottom:'50px'}} 
            className={showButton === true ? 'login_btn' : 'hide'} 
            onClick={() => {
                addStripeParams(props.cart, props.deliveryChoice, props.setApiData);
                openToggle();
            }
            }>
                Valider
            </button>
        </Link>
        </>  
    )
}

export default DeliveryMode