// affiche le point relais/retrait le plus proche de l'adresse utilisateur, sauvegarde le point séléctionné. Importé sur composant Delivery
import { useState } from "react";
import { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { addStripeParams } from "./AddStripeParams";
import Loader from './Loader';
import DeliveryPointSearch from "./DeliveryPointSearch";


import '../style/DeliveryRetirement.css';

function DeliveryRetirement(props) {
    const [apiDataGetUser, setApiDataGetUser] = useState();

    const [isFetched, setIsFetched] = useState(false);
	const [isFetchedUserAndXml, setIsFetchedUserAndXml] = useState(false);

    const [isError, setIsError] = useState(false); 
	const [isErrorUserAndXml, setIsErrorUserAndXml] = useState(false);
    
    const myLogin = process.env.REACT_APP_MY_LOGIN;
    const myPassword = process.env.REACT_APP_MY_PASSWORD;

    const today = new Date();   // définit la date de livraison à j+4 dans l'url
    const nextDay = new Date();
    nextDay.setDate(today.getDate()+4);
    const deliveryDate = nextDay.toLocaleDateString() 

    const numberForRetirement = // définit la recherche de points relais ou retrait
        props.isDeliveryRetirement === true ? 0 : props.isDeliveryRelay === true ? 2 : '';

    useEffect(() => {
        const userAddress = [];
        const userCity = [];
        const userPostCode = [];

        async function getUserDataAndGetXml() {  // retourne la liste des points retraits(bureaux de postes) proches de l'adresse utilisateur
            setIsFetched(true);
            await fetch(`${process.env.REACT_APP_SERVER_URL}/api/userData/getOne`, {
                method: 'GET',
                headers : {
                    'Content-type': 'application/json'
                },
                credentials: 'include'
            })
            .then(res => res.json())
            .then(data => {
                setIsFetched(false)
                setApiDataGetUser(data);
                userAddress.push(data.address);
                userPostCode.push(data.postCode);
                userCity.push(data.city);
            })
            .catch(() => setIsError(true))
        
            function fetchXml() {
            const data = this.responseText
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, 'text/xml');
            // affiche le point de retrait le plus proche de l'adresse principale de l'utilisateur
            const codeResponse = xmlDoc.getElementsByTagName("errorCode")[0].textContent;
            if(codeResponse === '0') {
                const names = xmlDoc.getElementsByTagName("nom");
                document.getElementById('closest_point_name').innerHTML = names[0].textContent

                const addresses = xmlDoc.getElementsByTagName("adresse1");
                document.getElementById('closest_point_address').innerHTML = addresses[0].textContent
                
                const postCodes = xmlDoc.getElementsByTagName("codePostal");
                document.getElementById('closest_point_postCode').innerHTML = postCodes[0].textContent

                const cities = xmlDoc.getElementsByTagName("localite");
                document.getElementById('closest_point_city').innerHTML = cities[0].textContent 
            } else {
                document.getElementById('closest_point_name').innerHTML = 'le service Web Colissimo est actuellement indisponible'
            } 
        }
            
        function error() {
            setIsErrorUserAndXml(true);
        }
        setIsFetchedUserAndXml(true)
        const xhr = new XMLHttpRequest();
        xhr.onload = fetchXml;
        xhr.onerror = error;
        // paramètres url: myLogin, myPassword = identifiants compte Colisimo, shippingDate = date de livraison estimée, 
        // filterRelay 0 = postes uniquement 1 = postes et commerces  2 = commerces uniquement 
        xhr.open('GET', `https://ws.colissimo.fr/pointretrait-ws-cxf/PointRetraitServiceWS/2.0/findRDVPointRetraitAcheminement?accountNumber=${myLogin}&password=${myPassword}&address=${userAddress}&zipCode=${userPostCode}&city=${userCity}&countryCode=FR&shippingDate=${deliveryDate}&filterRelay=${numberForRetirement}`);
        xhr.send();

        setIsFetchedUserAndXml(false);
    }
  
    getUserDataAndGetXml();  

    }, [deliveryDate, myLogin, myPassword, numberForRetirement])

    const saveDeliveryPoint = () => {
        props.setDeliveryPoint({
            name: document.getElementById('closest_point_name').outerText, 
            address: document.getElementById('closest_point_address').outerText, 
            postCode: document.getElementById('closest_point_postCode').outerText, 
            city: document.getElementById('closest_point_city').outerText
        });
        props.setIsAddress(true);
        props.setIsOpen1(false);
        props.setIsOpen3(true);
    }
    
    if(isError === true || !apiDataGetUser || isErrorUserAndXml === true) {
        return <div className='error_div'><p className='error_message'>Serveur indisponible</p></div>
    } else {
        return (
            <div id='delRet_container' className='delRet_container g_layout'>
                <div className='delRet_point_choice'>
                    <p className="delRet_title">
                        {props.isDeliveryRetirement === true ? 'Votre point de retrait :' : 'Votre point relais'}
                    </p>
                    {isFetched === true || isFetchedUserAndXml === true ? <Loader /> : 
                        <>
                        <p id="closest_point_name"></p>
                        <p id="closest_point_address"></p>
                        <div className="delRet_post_city">
                            <p id="closest_point_postCode"></p>
                            <p id="closest_point_city"></p>
                        </div>
                        </>
                    }
                    <Link smooth to='#toggle_link_landmark' aria-label='Lien vers le tunnel de paiement'>
                        <button className="login_btn" onClick={() => {
                            saveDeliveryPoint(); 
                            addStripeParams(props.cart, props.deliveryChoice, props.setApiData);
                        }}>
                            Valider
                        </button>
                    </Link> 
                </div>
                <DeliveryPointSearch 
                isDeliveryRetirement={props.isDeliveryRetirement} 
                isDeliveryRelay={props.isDeliveryRelay}
                isAddress={props.isAddress} 
                setIsAddress={props.setIsAddress}
                deliveryPoint={props.deliveryPoint}
                setDeliveryPoint={props.setDeliveryPoint}
                isOpen1={props.isOpen1} 
                setIsOpen1={props.setIsOpen1}
                isOpen3={props.isOpen3}
                setIsOpen3={props.setIsOpen3}
                apiData={props.apiData}
                setApiData={props.setApiData}
                cart={props.cart}
                deliveryChoice={props.deliveryChoice}
                />
            </div>
        )
    }
}

export default DeliveryRetirement