// page "à propos"
import '../style/About.css';
import Breadcrumb from "../components/Breadcrumb";
import Gallery from '../components/Gallery';
import { useState } from 'react';
import image4 from '../assets/4.jpg';
import image3 from '../assets/3.jpg';
import image5 from '../assets/5.jpg';
import image6 from '../assets/6.jpg';
import image8 from '../assets/8.jpg';
import image9 from '../assets/9.jpg';
import image10 from '../assets/10.jpg';
import image11 from '../assets/11.jpg';
import image13 from '../assets/13.jpg';
import image14 from '../assets/14.jpg';

function About() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const introPictures = [
        image4,
        image3,
        image5,
        image6,
        image9,
        image8,
        image10,
        image11,
        image13,
        image14
    ];

    return (
        <>
        <Breadcrumb 
        dest_name1='Notre rucher'
        style_link1={{fontWeight: 'bold'}}
        gt1={{display: 'none'}}
        gt2={{display: 'none'}}
        />
        <div className='g_layout apiary_container'>
            <div className='apiary_block'>
                <h1 className='parisienne h1_parisienne apiary_h1'>Notre rucher</h1>
                <Gallery pictures={introPictures} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} /> 
                <div className='apiary_text'>
                    <p >Situé dans le Béarn près de Pau, le Rucher des Albizias s'est installé sur une prairie bordant le Gave d'Oloron, une rivière Pyrénéenne.<br/><br/>Son principal atout : La présence d'une forêt sauvage longeant la rivière sur plusieurs kilomètres, composée d'un eco-système riche et varié : merisiers, acacias, tilleuls et frênes côtoient les lierres, ronces, chèvrefeuilles ou noisetiers, fournissant en abondance pollen et nectar aux abeilles presque toute l'année.</p>
                    <ul className='apiary_list'>
                        <li>Notre miel :</li>
                        <li>- n'est pas mélangé à d'autres miels,</li>
                        <li>- est produit et récolté sur l'exploitation,</li>
                        <li>- n'est pas prélevé dans les corps de ruche, dans le respect du développement naturel de la colonie.</li>
                    </ul>
                    <div className='separate_block'>
                        <div className='separate'></div>
                    </div>
                </div>
                
            </div>
        </div>
        </>  
    )   
}

export default About