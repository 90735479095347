// modifie les coordonnées utilisateur(adresse principale,...) 
import Form from '../components/Form';
import '../style/Modify.css';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';

function Modify({ isAuth, setIsAuth }) {
	const urlParams = useParams();
	
  return (
	<>
	<Breadcrumb 
        dest_name1='Mon compte'
        dest_link1='/user-account'
        dest_name2='Modifier'
		style_link2={{fontWeight: 'bold'}}
        />
    <div id='modify_container' className='g_layout'>
		<h1 className='modify_h1 h1_desk_fontSize'>Modifier vos coordonnées</h1>
        <Form
        id='modify_form'
		textButton='Enregistrer'
		url={`${process.env.REACT_APP_SERVER_URL}/api/userData/${urlParams.id}`}
		navUrl='/user-account'
		method='PUT'
		styleConfirm={{display:'none'}}
		stylePassword={{display:'none'}}
		styleDelivery={{display:'none'}}
		styleEmail={{display:'none'}}
		styleOldPassword={{display:'none'}}
		styleDeliveryFirstName={{display:'none'}}
		styleDeliveryLastName={{display:'none'}}
		styleDeliveryAddress={{display:'none'}}
		styleDeliveryAdditional={{display:'none'}}
		styleDeliveryPostCode={{display:'none'}}
		styleDeliveryCity={{display:'none'}}
        styleTitle={{display:'none'}}
		requiredTitle={false}
		requiredCivility={true}
		requiredLastName={true}
		requiredFirstName={true}
		requiredAddress={true}
		requiredPostCode={true}
		requiredCity={true}
		requiredPhone={true}
		placeholderAdditional= 'optionnel'
		isAuth={isAuth}
		setIsAuth={setIsAuth}
		errorContent={"Une erreur s'est produite sur le serveur"}
        />
    </div>
	</>
  )
}

export default Modify