// compose le champ de mot de passe sur le formulaire, importé sur Form 
import {useState} from 'react'
import '../style/FormGroupPassword.css';

function FormPassword(props) {
    const {
        text, 
        handleChange,
        className,
        name,
        value,
        invalid,
        disabled,
        required,
        style,
        styleList,
        placeholder,
    }= props;

const [isVisiblePassword, setIsVisiblePassword] = useState(false);
    return (
        <div className="form_group" style={style}>
            <label htmlFor="password" className="form_label">{text}</label>
            <div>
                <input
                    required={required}
                    id={name}
                    className={className}
                    type={isVisiblePassword === true ? 'text' : 'password'}
                    name={name}
                    onChange={handleChange}
                    value={value}
                    placeholder={placeholder}
                />
                <button 
                className="eye_button" 
                aria-label='mot de passe visible'
                onClick={() => {isVisiblePassword === false ? setIsVisiblePassword(true) : setIsVisiblePassword(false)}}
                disabled={disabled}
                >
                {isVisiblePassword === true ? 
                    <i className="fa-solid fa-eye icon_visible"></i> : <i className="fa-solid fa-eye-slash icon_visible"></i>
                }
                </button>
            </div>
            <div className="invalid_feedback">
                {invalid}
            </div>
            <div className="password_list" style={styleList}>
                <ul className="password_item">Votre mot de passe doit contenir :
                    <li className="uppercase">
                    - au moins une majuscule,
                    </li>
                    <li className="lowercase">
                    - au moins une minuscule,
                    </li>
                    <li className="figure">
                    - au moins un chiffre,  
                    </li>
                    <li className="special">
                    - au moins un caractère spécial ($, % ...),
                    </li>
                    <li className="min_lenght">
                    - de 8 à 20 caractères.
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default FormPassword