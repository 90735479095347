// logique de mot de passe oublié
import Form from "../components/Form";
import '../style/ForgotPassword.css';
import Breadcrumb from "../components/Breadcrumb";

function ForgotPassword() {
  	return (
		<>
		<Breadcrumb 
			dest_name1='Connexion'
			dest_link1='/login'
			dest_name2='Mot de passe oublié'
			style_link2={{fontWeight: 'bold'}}
			gt2={{display: 'none'}}
			/>
		<div id="forgot_container" className='g_layout'>
			<h1 className="forgot_h1 h1_desk_fontSize">Demande de nouveau mot de passe</h1>
			<Form
			id='forgot_form'
			textButton='Envoyer'
			url={`${process.env.REACT_APP_SERVER_URL}/api/reset`}
			method='POST'
			errorContent={'utilisateur introuvable / erreur serveur'}
			successContent={'Un email vous a été envoyé(vérifiez vos spams) contenant un lien de réinitialisation.'}
			styleCivility={{display:'none'}}
			styleFirstName={{display:'none'}}
			styleLastName={{display:'none'}}
			styleAddress={{display:'none'}}
			styleDelivery={{display:'none'}}
			styleCity={{display:'none'}}
			stylePostCode={{display:'none'}}
			styleAdditional={{display:'none'}}
			stylePhone={{display:'none'}}
			styleOldList={{display: 'none'}}
			stylePassword={{display: 'none'}}
			styleConfirm={{display: 'none'}}
			styleTitle={{display: 'none'}}
			styleOldPassword={{display: 'none'}}
			requiredEmail={true}
			/>
		</div>
		</>
  	)
}

export default ForgotPassword