import { useState, useEffect } from "react"

function useFetch(url) {
    const [apiData, setApiData] = useState();
    const [isFetched, setIsFetched] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        async function datafetch() {
            setApiData(null);
            await fetch(url, {
                method: 'GET',
                headers : {
                    'Content-type': 'application/json'
                },
                credentials: 'include'
            })
            .then(res => res.json())
            .then(data => setApiData(data))
            .catch(() => setIsError(true))
            .finally(() => setIsFetched(true))  
        }
        datafetch();
    }, [url])

    return { apiData, isFetched, isError }
}

export default useFetch