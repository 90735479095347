import { HashLink as Link } from 'react-router-hash-link';
import { useState } from 'react';
import CartLogic from '../components/CartLogic';
import Breadcrumb from '../components/Breadcrumb';
import useFetch from '../components/UseFetch';
import Loader from '../components/Loader';
import UseModal from '../components/UseModal';
import '../style/Cart.css';

function Cart({ isAuth, cart, updateCart }) {
    const [isModal, setIsModal] = useState(false);
    
    const articlesTotalQuantity = cart.length === 0 ? null : cart.map((article => article.quantity)).reduce((a, b) => a+b);
    
    const cartLinkState = {
        previousPage: '/cart#cart_container'
    }
    
    const {apiData, isFetched} = useFetch(`${process.env.REACT_APP_SERVER_URL}/api/userData/getOne`);

    return (
        <>
        <Breadcrumb 
        dest_name1='Panier'
        style_link1={{fontWeight: 'bold'}}
        gt1={{display: 'none'}}
        gt2={{display: 'none'}}
        />
        <div id='cart_container' className='g_layout'>
            {isFetched === false ? <Loader /> :
            <>
            <h1 className='cart_h1 h1_desk_fontSize'>Panier</h1>
            <CartLogic cart={cart} updateCart={updateCart} />
            <div style={{margin: '50px 0 10px 0'}}>
                {cart.length < 1 ?
                    <button className='login_btn cart_log_btn' onClick={() => setIsModal(!isModal)}>Commander</button> :
                    isAuth === true && apiData !== null ?
                    <Link to={articlesTotalQuantity >7 ? '' : '/cart/checkout#checkout_container'}>{/* livraisons limitées à 5kg(7 pots) */}
                        <button className='login_btn cart_log_btn' onClick={() => articlesTotalQuantity >7 ? setIsModal(!isModal) : ''}>
                            Commander
                        </button>
                    </Link> :
                    <Link 
                    to={isAuth === true && apiData === null ? '/user-account/registration#register_container' : '/login#login_container'} 
                    state={cartLinkState} 
                    className='link_effect link_underline'>
                        Enregistrer vos coordonnées pour commander
                    </Link>
                }
            </div>
            <UseModal
                isModal={isModal}
                setIsModal={setIsModal} 
                style_second_btn={{display: 'none'}}
                option1_btn={'Ok'} 
                option2_btn={''} 
                modal_text={articlesTotalQuantity >7 ? 'Quantité maximale(7 articles) dépassée' : 'Votre panier est vide...'}
                handleClick={() => {
                    setIsModal(!isModal); 
                }} 
                />  
            </>
            }
        </div>
        </>
    )
}

export default Cart