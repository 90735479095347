// tunnel de paiement
import { HashLink as Link } from 'react-router-hash-link';
import { useState } from 'react';
import Toggle from '../components/Toggle';
import CartLogic from '../components/CartLogic';
import DeliveryMode from '../components/DeliveryMode';
import DeliveryAddress from '../components/DeliveryAddress';
import Breadcrumb from '../components/Breadcrumb';
import UseModal from '../components/UseModal';
import Loader from '../components/Loader';
import '../style/Checkout.css';

function Checkout({ cart, delivery, updateDelivery }) {
	if(cart.length === 0) window.location.href = "/";

	const [isOpen1, setIsOpen1] = useState(true);
	const [isOpen2, setIsOpen2] = useState(false);
	const [isOpen3, setIsOpen3] = useState(false);

	const [deliveryChoice, setDeliveryChoice] = useState(null); // enregistre le mode de livraison(domicile, relais etc...)
	const [deliveryAddress, setDeliveryAddress] = useState(); // si adresse de livraison à domicile, enregistre celle-ci sous forme d'id
	const [deliveryPoint, setDeliveryPoint] = useState(); // si adresse de livraison en point retrait, enregistre celle-ci sous forme d'objet
	const [isAddress, setIsAddress] = useState(false); // vérifie la présence de l'adresse avant paiement

	const [isModal, setIsModal] = useState(false);
	const [modalText, setModalText] = useState('');

	const [isAccepted, setIsAccepted] = useState(false);

	const [apiData, setApiData] = useState(null); // url de la page de paiement de stripe reçue dans Delivery

	const [isLoader, setIsLoader] = useState(false);
	
	function redirect() {
		if(deliveryChoice === null ) {
			setIsModal(!isModal);
			setModalText('Veuillez choisir le mode de livraison');
		} else if(isAddress === false) {
			setIsModal(!isModal);
			setModalText('Veuillez valider votre adresse');
		} else if(isAccepted === false) {
			setIsModal(!isModal);
			setModalText('Veuillez valider les conditions générales de vente');
		} else if(apiData === null) {
			setIsModal(!isModal);
			setModalText("Module de paiement indisponible");
		} else {
			setIsLoader(true);
			if(deliveryAddress === undefined) {
				updateDelivery({
					name: deliveryChoice.title, 
					price: deliveryChoice.price, 
					deliveryPoint: {
						name: deliveryPoint.name, 
						address: deliveryPoint.address, 
						postCode: deliveryPoint.postCode,
						city: deliveryPoint.city
					}
				});
				
				window.location.href = apiData.url;
				setIsLoader(false);
			} else {
				updateDelivery({name: deliveryChoice.title, price: deliveryChoice.price, deliveryAddress: deliveryAddress.id});
				window.location.href = apiData.url;
				setIsLoader(false);
			}
		}
	}

	return (
		<>
		<Breadcrumb 
		dest_name1='Panier'
		dest_link1='/cart'
		dest_name2='Commande'
		style_link2={{fontWeight: 'bold'}}
		gt2={{display: 'none'}}
		/>
		<div id='checkout_container' className='g_layout'>
			<h1 className='checkout_h1 h1_desk_fontSize'>Commande</h1>
			{isLoader === true ? <Loader/> : apiData !== null && apiData === undefined ? <p className='error_message'>Une erreur s'est produite sur le serveur</p> : 
				<div className='checkout_block'>
					<div className='checkout_toggle_block'>
						<Toggle 
						isOpen={isOpen1} 
						setIsOpen={setIsOpen1}
						handleClick={() => {
							isOpen1 === false ? setIsOpen1(true) : setIsOpen1(false);
							setIsOpen2(false); 
							setIsOpen3(false);
						}} 
						title="1-Choisir la livraison"
						content={
							<DeliveryMode 
							cart={cart} 
							deliveryChoice={deliveryChoice}
							setDeliveryChoice={setDeliveryChoice}
							isOpen1={isOpen1} 
							setIsOpen1={setIsOpen1}
							isOpen2={isOpen2} 
							setIsOpen2={setIsOpen2}
							isOpen3={isOpen3}
							setIsOpen3={setIsOpen3}
							apiData={apiData}
							setApiData={setApiData}
							isAddress={isAddress} 
							setIsAddress={setIsAddress}
							deliveryPoint={deliveryPoint}
							setDeliveryPoint={setDeliveryPoint}
							/>
						}
						/>
					</div>
					<div className='checkout_toggle_block'>
						<Toggle 
						isOpen={isOpen2} 
						setIsOpen={setIsOpen2}
						handleClick={() => {
							isOpen2 === false ? setIsOpen2(true) : setIsOpen2(false);
							setIsOpen1(false); 
							setIsOpen3(false); 
						}} 
						title="2-Choisir votre adresse de livraison"
						content={
							<DeliveryAddress 
							isAddress={isAddress}
							setIsAddress={setIsAddress}
							deliveryAddress={deliveryAddress}
							setDeliveryAddress={setDeliveryAddress}
							isOpen2={isOpen2} 
							setIsOpen2={setIsOpen2}
							isOpen3={isOpen3} 
							setIsOpen3={setIsOpen3}
							/>
						}
						/>
					</div>
					<div className='checkout_toggle_block'>
						<Toggle 
						isOpen={isOpen3} 
						setIsOpen={setIsOpen3}
						handleClick={() => {
							isOpen3 === false ? setIsOpen3(true) : setIsOpen3(false); 
							setIsOpen1(false);
							setIsOpen2(false);
						}}
						title="3-valider la commande"
						content={
							<div className='checkout_toggle_cartLogic' >
								<CartLogic cart={cart} delivery={delivery} deliveryChoice={deliveryChoice} />
								<div className='checkout_contract_block'>
									<button className='checkout_contract_btn' onClick={() => setIsAccepted(!isAccepted)} aria-label='valider CGV'>
										<div className='checkout_checkbox'>
											<div className='checkout_icon'>
												<i 
												className="fa-solid fa-check checkout_check" 
												style={isAccepted === true ? {display : 'flex'} : {display: 'none'}}
												></i>
											</div>
										</div>
									</button>
									<div className='checkout_cgv_link'>
										<span>J'accepte les </span>
										<Link 
										className='link_effect link_underline' to='/info#cgv_container'>
											conditions générales de vente
										</Link>
										<span> et suis informé sur mon droit de rétractation</span>
									</div>
								</div>
								{/* redirection sur l'url de la page de paiment */}
								<button className='login_btn checkout_log_btn' onClick={() => redirect()}>Confirmer et payer</button>
							</div> 
						} 
						/>
					</div>
				</div>
			}
			<UseModal
			isModal={isModal}
			setIsModal={setIsModal} 
			style_second_btn={{display: 'none'}}
			option1_btn={'Ok'} 
			option2_btn={''} 
			modal_text={modalText}
			handleClick={() => {
				setIsModal(!isModal); 
			}} 
			/>
		</div>
		</>
  	)
}

export default Checkout