// compose tous les champs du formulaire(sauf mot de passe), importé sur Form
import React from "react";

function FormInput(props) {
    const {
        text, 
        handleChange,
        className,
        type,
        name,
        value,
        invalid,
        required,
        style,
        checked,
        placeholder,
        readOnly
    } = props;

    return (
        <div className="form_group" style={style}>  
            <label htmlFor={name} className="form_label">{text}</label>
            <input
                required={required}
                id={name}
                className={className}
                type={type}
                name={name}
                onChange={handleChange}
                value={value}
                checked={checked}
                placeholder={placeholder}
                readOnly={readOnly}
            />
            <div className="invalid_feedback">
                {invalid}
            </div>
        </div>
    )
}

export default FormInput