// affiche les données utilisateur sur PersonnalComp, AddressComp, AddressToggles
function UserInfo({ civility, firstName, lastName, address, additional, postCode, city }) {
    return (
        <div>
            <div className='personnal_display'>
                <i className="fa-solid fa-user pers_icon"></i>
                <span>{civility}</span>
                <span>{`${firstName} ${lastName}`}
                </span>
                <div className='separate_block'>
                    <div className='separate sep_pers'></div>
                </div>
            </div>
            <div className='personnal_display'>
                <i className="fa-solid fa-location-dot pers_icon"></i>
                <span>{address}</span>
                <span>{additional}</span>
                <span>{postCode}</span>
                <span>{city}</span>
                <div className='separate_block'>
                    <div className='separate sep_pers'></div>
                </div>
            </div>
        </div>
    )
}

export default UserInfo