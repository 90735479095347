// affiche les coordonnées utilisateur
import Breadcrumb from '../components/Breadcrumb';
import PersonnalComp from '../components/PersonnalComp';

function Personnal() {
  return (
	<>
	<Breadcrumb 
	dest_name1='Mon compte'
	dest_link1='/user-account'
	dest_name2='Mes coordonnées'
	style_link2={{fontWeight: 'bold'}}
	gt2={{display: 'none'}}
	/>
	<PersonnalComp />
	</>
  )
}

export default Personnal