// page de succes du paiement stripe
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function Success({ delivery, cart }) {
	const [apiData, setApiData] = useState();
	const [isFetched, setIsFetched] = useState(false);
	const [isError, setIsError] = useState(false);
	const navigate = useNavigate();
	const urlId =  window.location.href.split('=')[1];

	useEffect(() => {
		async function verifyModifySaveSend() {
			if(urlId === undefined) {
				return navigate('/');
			} else {
				fetch(`${process.env.REACT_APP_SERVER_URL}/api/checkout/getSession/${urlId}`, {  //vérifie si le paiement a bien été effectué
					method: 'GET',
					headers: {
						'Content-type': 'application/json'
					},
					credentials: 'include'
				})
				.then(res => {
					if(res.ok) {
						return res.json();
					} else {
						return res.status;
					}
				})	
				.then(data => setApiData(data))
				.catch(() => setIsError(true));
				
				if(apiData === 404 ) navigate('/');

				const EachProductquantity = cart.map((element) => element.quantity);
				const quantityTotal = EachProductquantity.reduce((a, b) => a+b);
				const EachProductTotal = cart.map((element) => element.price * element.quantity);
				const total = EachProductTotal.reduce((a, b) => a+b);
				const totalTtc = total + delivery.price;

				const confirmBody = delivery.deliveryAddress ? {deliveryAddress: delivery.deliveryAddress} : 
				delivery.deliveryPoint ? {deliveryPoint: delivery.deliveryPoint} : "";
				
				const articles = cart.map((element) => {
					return {
						title: element.title,
						price: element.price,
						quantity: element.quantity,
						_id: element.id
					}
				});

				if(apiData === 'complete') {
					fetch(`${process.env.REACT_APP_SERVER_URL}/api/order`, {	//enregistre la commande
						method: 'POST',
						body: JSON.stringify({
							articles : articles,
							deliveryName: delivery.name,
							deliveryPrice: delivery.price,
							quantityTotal: quantityTotal,
							total: total,
							totalTtc: totalTtc,
							status: 'en cours'
						}),
						headers: {
							'Content-type': 'application/json'
						},
						credentials: 'include'
					})
					.then(res => res.json())
					.catch(() => setIsError(true))
				
					.then(() => 
						fetch(`${process.env.REACT_APP_SERVER_URL}/api/confirm`, {  //envoie le récapitulatif de la commande par mail
							method: 'POST',
							body: JSON.stringify(confirmBody),
							headers: {
								'Content-type': 'application/json'
							},
							credentials: 'include'
						})
						.then(res => res.json())
						.catch(() => setIsError(true))
					);
				}
				
				setIsFetched(true);	
				localStorage.clear();
				setTimeout(() => {
					window.location.href="/";
				}, 6000)
			}
		}
		verifyModifySaveSend();
	}, [apiData, cart, delivery.deliveryAddress, delivery.deliveryPoint, delivery.name, delivery.price, navigate, urlId]);

  	return isError === true ? <div className='error_div'><p className='error_message'>Une erreur s'est produite sur le serveur</p></div> : 
	isFetched === false ? <div className='g_layout'><Loader/></div> : (
		<div id='success_container' className='g_layout' style={{paddingBottom: '100%'}}>
			<h1>Merci pour votre commande !</h1>
			<p>Votre paiement a bien été effectué, un reçu vous a été envoyé par mail(vérifiez vos spams).</p><br />
			<p>Vous serez redirigé vers l'accueil dans quelques secondes...</p>
		</div>
  	)
}

export default Success