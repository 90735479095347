// calcul des frais de port
export const homePrice = (quantity) => { // à domicile
    if(quantity === 1) return 8.10;
    if(quantity === 2) return 10.15;
    if(quantity > 2) return 15.60;
}

export const homeSignPrice = (price, quantity) => { // à domicile contre signature
    if(price <=50) return homePrice(quantity) +2.90;
    if(price > 50) return homePrice(quantity) +4.40;
}

export const relayPrice = (quantity) => { // en point retrait/relais
    if(quantity === 1) return 7.40;
    if(quantity === 2) return 9.45;
    if(quantity > 2) return 14.90;
}