// gère l'enregistrement des coordonnées utilisateur
import Form from '../components/Form';
import Breadcrumb from '../components/Breadcrumb';
import UseFetch from '../components/UseFetch';
import Loader from '../components/Loader';
import { useLocation } from 'react-router-dom';
import '../style/Registration.css';

function Registration({ isAuth, setIsAuth }) {
    const location = useLocation();
    const navUrl = location.state == null ? '/user-account' : location.state.previousPage;

    const { apiData, isFetched, isError } = UseFetch(`${process.env.REACT_APP_SERVER_URL}/api/userData/getOne`);
    return (
        <>
        <Breadcrumb 
        dest_name1='Mon compte'
        dest_link1='/user-account'
        dest_name2='compléter mon compte'
        dest_link2='/user-account'
		style_link2={{fontWeight: 'bold'}}
        gt2={{display: 'none'}}
        />
        <div id="register_container" className='g_layout'>
            {isError === true ? <div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div> : 
            isFetched === false ? <Loader /> :
                !apiData && isFetched === true ? 
                <div className="register_block">
                    <h1 className='registration_h1 h1_desk_fontSize'>MES COORDONNES</h1>
                    <Form
                    id= 'registration_form'
                    textButton= 'Valider'
                    url={`${process.env.REACT_APP_SERVER_URL}/api/userData`}
                    method='POST'
                    navUrl={navUrl}
                    styleConfirm={{display:'none'}}
                    styleEmail={{display:'none'}}
                    stylePassword={{display:'none'}}
                    styleOldPassword={{display:'none'}}
                    styleTitle={{display:'none'}}
                    requiredPassword={false}
                    requiredEmail={false}
                    requiredTitle={false}
                    requiredCivility={true}
                    requiredFirstName={true}
                    requiredLastName={true}
                    requiredAddress={true}
                    requiredCity={true}
                    requiredPostCode={true}
                    requiredPhone={true}
                    placeholderAdditional= 'optionnel'
                    isAuth={isAuth} 
                    setIsAuth={setIsAuth}
                    errorContent={"Une erreur s'est produite sur le serveur"}
                    />
                </div> :  <p>Vous êtes déjà enregistré !</p>
            }
        </div>
        </>
    )
}

export default Registration