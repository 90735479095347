// affiche le produit sélectionné + description
import { useParams } from 'react-router-dom';
import UseFetch from '../components/UseFetch';
import '../style/Article.css';
import pot_printemps from '../assets/printemps.jpg';
import pot_tilleul from '../assets/tilleul.jpg';
import pot_ete from '../assets/ete.jpg';
import AddArticle from '../components/AddArticle';
import Breadcrumb from '../components/Breadcrumb';
import Loader from '../components/Loader';

function Article({ cart, updateCart }) {
    const urlParams = useParams();
    
    const { apiData, isFetched, isError } = UseFetch(`${process.env.REACT_APP_SERVER_URL}/api/article/${urlParams.id}`)
    
    return (
        <>
        <Breadcrumb 
        dest_name1='Miels'
        dest_link1='/products'
        dest_name2='Article'
        style_link2={{fontWeight: 'bold'}}
        gt2={{display: 'none'}}
        />
        <div id='article_container' className='g_layout'>
            {isError === true ? <div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div> : 
            isFetched === false ? <Loader /> :
                <> 
                <div className='article_block'>
                    <div className='product_card art_card'>
                        <div className='article_card_block'>
                        <img 
                        src={
                            apiData._id === '647ef364573064f42bf0c789' ? pot_printemps :
                            apiData._id === '64b6aeb8e8fa63aded29eed6' ? pot_tilleul : pot_ete  
                        } 
                        alt="pot de miel" 
                        className='img_def' 
                        />
                        </div>
                    </div>
                    <div className='article_description'>
                        <div className='h1_article_block'>
                            <h1 className='article_h1 parisienne h1_parisienne'>{apiData.title}</h1>
                            <span className='h1_article_span'>500g</span>
                        </div>
                        <h2 className='article_price'>{`${apiData.price}€`}</h2>
                    </div>
                    <div className='art_addCart_block'>
                        <p className='article_text'>Conditionné en pot en verre, le miel est produit en France, 
                            dans le département des Pyrénées-Atlantiques.
                        </p>
                        <p className='article_text2'>{apiData.main}</p>   
                        {apiData.stock >1 ?  
                            <p className='article_stock'>En stock</p> :
                            <p className='article_out_stock'>rupture de stock</p>
                        }
                        <div className='article_addCart'>
                            {apiData.stock <1 ? null : 
                                <AddArticle 
                                cart={cart} 
                                updateCart={updateCart} 
                                price={apiData.price} 
                                title={apiData.title}
                                id={apiData._id}
                                price_id={apiData.price_id}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className='article_info_block'>
                    <h3 className='parisienne article_info_title article_info_desc'>Description</h3>
                    <p className='article_info_text'>{apiData.description}</p>
                    <h3 className='parisienne article_info_title'>Plantes</h3>
                    <p className='article_info_text'>{apiData.plants}</p>
                    <h3 className='parisienne article_info_title'>Propriétés</h3>
                    <p className='article_info_text'>{apiData.properties}</p>
                    <h3 className='parisienne article_info_title'>Utilisations</h3>
                    <p className='article_info_text'>{apiData.uses}</p>
                </div> 
                </>
            }       
        </div>
        </>
    )   
}

export default Article