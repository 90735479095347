// logique de réinitialisation du mot de passe
import '../style/Reset.css';
import { useParams } from 'react-router-dom';
import Form from '../components/Form';
import Loader from '../components/Loader';
import { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Reset({ isAuth, setIsAuth }) {
    const params = useParams();
    const [apiData, setApiData] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const [loader, setLoader] =useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        async function getResetToken() {
            setLoader(true);
            await fetch(`${process.env.REACT_APP_SERVER_URL}/api/reset/${params.resetToken}`, {
                headers : {
                    'Content-type': 'application/json'
                },
                credentials: 'include'
            })
            .then(res => {
                if(res.ok) {
                    setLoader(false);
                    setIsSuccess(true);
                    return res.json(); 
                }else {
                    setLoader(false);
                    return res.json();
                }
            })
            .then((data) => setApiData(data.userEmail))
            .catch(() => setIsError(true))
        }
        getResetToken();
    }, [params.resetToken]);

    return (
        <div id='reset_container' className='g_layout'>
            {loader === true ? <Loader /> : 
            isSuccess === true ?
            <div>
                <div>
                    <h1 className='h1_reset h1_desk_fontSize'>Nouveau mot de passe</h1>
                </div>
                {isError === true ? <div className='error_div'><p className='error_message'>Une erreur s'est produite sur le serveur</p></div> : 
                    <Form 
                        id='reset_form'
                        textButton='Enregistrer'
                        url= {`${process.env.REACT_APP_SERVER_URL}/api/reset/${apiData}`}
                        method='PUT'
                        navUrl='/'
                        errorContent={''}
                        styleCivility={{display:'none'}}
                        styleFirstName={{display:'none'}}
                        styleLastName={{display:'none'}}
                        styleAddress={{display:'none'}}
                        styleDelivery={{display:'none'}}
                        styleConfirm={{display:'none'}}
                        styleCity={{display:'none'}}
                        stylePostCode={{display:'none'}}
                        styleAdditional={{display:'none'}}
                        stylePhone={{display:'none'}}
                        styleEmail={{display:'none'}}
                        styleOldPassword={{display:'none'}}
                        styleTitle={{display:'none'}}
                        requiredPassword={true}
                        placeholderPassword='Veuillez saisir votre nouveau mot de passe'
                        isAuth={isAuth}
                        setIsAuth={setIsAuth}
                    />
                }
            </div> :
            <div>
                <div className='reset_fail'>
                    <p className='reset_sorry'>Désolé, le lien a expiré...</p>
                    <Link className='reset_back_link link_effect link_underline' to={'/login#login_container'}>
                        Retourner sur la page de connexion
                    </Link>
                </div>
            </div>}
        </div>
    )
}

export default Reset