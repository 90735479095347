// affiche une liste de points relais/retrait, sauvegarde le point séléctionné. Importé sur le composant DeliveryPointSearch
import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { addStripeParams } from "./AddStripeParams";
import UseModal from "./UseModal";

function DeliveryPointResult(props) {
    const [isModal, setIsModal] = useState(false);
  
    const changeHours = (element) => {
        const change = element === '00:00-00:00 00:00-00:00' ? 'fermé' : element;
        return change; 
    }

    const saveDeliveryPoint = (name, address, postCode, city, leave) => {
        if(leave === 'false') {
            props.setDeliveryPoint({name: name, address: address, postCode: postCode, city: city}); 
            props.setIsAddress(true);
            props.setIsOpen1(false);
            props.setIsOpen3(true);
            addStripeParams(props.cart, props.deliveryChoice, props.setApiData);
        } else {
            setIsModal(!isModal);
        }
    }

    return  (
        <>
        <div 
        className='delPoint_result_block' 
        style={props.isFetched === undefined && props.apiDataXml === null ? {display: 'none'}: {display: 'block'}}
        >
            <div className='delPoint_scrollDiv'>
                <div className="delPoint_result">
                    {props.points ? props.points.map((element, index) => {
                        return (
                            <div 
                            key={index} 
                            className='delPoint_card' 
                            style={element[0] === undefined ? {display: 'none'} :
                            element[12] === 'false' ? {backgroundColor: 'rgb(245, 245, 245)'} : 
                            {backgroundColor: 'rgb(220, 220, 220)', opacity: '0.6'}}
                            >
                                <div>
                                    <div className='delPoint_name_dist'>
                                        <div className='delPoint_name_block'>
                                            <p className='delPoint_name delPoint_fields'> {element[0]}</p>
                                        </div>
                                        <div className='delPoint_locBlock'>
                                            <i 
                                            className="fa-solid fa-location-dot delPoint_locDot"
                                            style={element[12] === 'false' ? {color: 'rgb(100, 100, 100)'} : {color: 'black'}}
                                            ></i>
                                            <p 
                                            className='delPoint_distance' 
                                            style={element[12] === 'false' ? {color: 'rgb(100, 100, 100)'} : {color: 'black'}}
                                            >
                                                {element[4]} m
                                            </p>
                                        </div>
                                    </div>
                                    <p className='delPoint_address delPoint_fields'>{element[1]}</p>
                                    <p className='delPoint_post_city delPoint_fields'>{element[2]} {element[3]}</p>
                                </div>
                                <div className='delPoint_infoDiv'>
                                    <p className="hours_closing">Horaires d'ouverture :</p>
                                    <div className='hours_field'>
                                        <p className="hours_day">Lundi</p>
                                        <p className="hours">{changeHours(element[5])}</p>
                                    </div>
                                    <div className='hours_field'>
                                        <p className="hours_day">Mardi</p>
                                        <p className="hours">{changeHours(element[6])}</p>
                                    </div>
                                    <div className='hours_field'>
                                        <p className="hours_day">Mercredi</p>
                                        <p className="hours">{changeHours(element[7])}</p>
                                    </div>
                                    <div className='hours_field'>
                                        <p className="hours_day">Jeudi</p>
                                        <p className="hours">{changeHours(element[8])}</p>
                                    </div>
                                    <div className='hours_field'>
                                        <p className="hours_day">Vendredi</p>
                                        <p className="hours">{changeHours(element[9])}</p>
                                    </div>
                                    <div className='hours_field'>
                                        <p className="hours_day">Samedi</p>
                                        <p className="hours">{changeHours(element[10])}</p>
                                    </div>
                                    <div className='hours_field'>
                                        <p className="hours_sunday">Dimanche</p>
                                        <p>{changeHours(element[11])}</p>
                                    </div>
                                </div>
                                
                                <div className='delPoint_valid_btn'>
                                    <Link smooth to={element[12] === 'false' ? '#toggle_link_landmark' : ''}>
                                        <button 
                                        className='login_btn pointSearch_btn'
                                        onClick={() => saveDeliveryPoint(element[0], element[1], element[2], element[3], element[12])} 
                                        >
                                            {props.isDeliveryRetirement === true ? 'Valider ce retrait' : 'Valider ce relais'}
                                        </button>
                                    </Link>
                                </div>
                                <UseModal
                                isModal={isModal}
                                setIsModal={setIsModal} 
                                style_second_btn={{display: 'none'}}
                                option1_btn={'Ok'} 
                                modal_text={'Cet établissement est fermé pour congés'}
                                handleClick={() => setIsModal(!isModal)} 
                                />
                            </div> 
                        )
                    }) : ''}
                </div>
            </div>
        </div>
        </>
    )
}

export default DeliveryPointResult