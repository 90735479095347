// fonctions principales du panier, importé sur les pages Cart, Checkout
import { useLocation } from 'react-router-dom';
import { homePrice, /*homeSignPrice, relayPrice*/ } from './DeliveryPrice';
import pot_printemps from '../assets/printemps.jpg';
import pot_tilleul from '../assets/tilleul.jpg';
import pot_ete from '../assets/ete.jpg';
import '../style/CartLogic.css';

function CartLogic(props) {
    const location = useLocation();
    
    function IncreaseQuantity(id) {
        const currentArticle = props.cart.find((art) => art.title === id);
        const cartFilteredCurrentArticle = props.cart.filter((art) => art.title !== id);
        props.updateCart([{...currentArticle, quantity : currentArticle.quantity +1}, ...cartFilteredCurrentArticle]) 
    }

    function DecreaseQuantity(id) {
        const currentArticle = props.cart.find((art) => art.title === id);
        const cartFilteredCurrentArticle = props.cart.filter((art) => art.title !== id);
        props.updateCart([{...currentArticle, quantity : currentArticle.quantity -1}, ...cartFilteredCurrentArticle]) 
    }

    function deleteArticle(id) {
        const cartFilteredCurrentArticle = props.cart.filter((art) => art.title !== id);
        props.updateCart([...cartFilteredCurrentArticle]);
    }
    // montant total du panier
    const total = props.cart.length === 0 ? '0' : props.cart.map((article => article.quantity*article.price)).reduce((a, b) => a+b);
    //  quantité totale des articles du panier
    const quantity = props.cart.length === 0 ? '0' : props.cart.map((article => article.quantity)).reduce((a, b) => a+b);
   
    const deliveryDisplay = () => { // affichage du prix de livraison
        let cartDelivery = '';
        if(location.pathname === '/cart') { // sur le panier
            if(props.cart.length >0) {
                const shipping = homePrice(quantity).toString().replace('.', ',');
                cartDelivery = homePrice(quantity) === 7.6 ? shipping + 0 : shipping 
            } else {
                cartDelivery = '0';
            }
        return cartDelivery;
        } else { // sur le tunnel de paiement et les commandes en cours(Checkout, History)
            if(props.deliveryChoice) {
                const shipping = [props.deliveryChoice.price].toString().replace('.', ',');
                const otherDelivery = 
                props.deliveryChoice.price === 7.6 || props.deliveryChoice.price === 6.9 || props.deliveryChoice.price === 10.5? 
                shipping + 0 : shipping;
                return otherDelivery
            }  
        }
    }

    const totalDisplay = () => { // affichage du prix total
        let cartTotal = '';
        if(location.pathname === '/cart') {
            if(props.cart.length >0) {
                const shipping = (homePrice(quantity) + total).toString().replace('.', ',');
                cartTotal = homePrice(quantity) === 7.6 ? shipping + 0 : shipping;
            } else {
                cartTotal = '0';
            }
        return cartTotal;
        } else {
            if(props.deliveryChoice) {
            const shipping = [total + props.deliveryChoice.price].toString().replace('.', ',');
            const otherTotal = 
            props.deliveryChoice.price === 7.6 || props.deliveryChoice.price === 6.90 || props.deliveryChoice.price === 10.5? 
            shipping + 0 : shipping;
            return otherTotal;
            }        
        }
    }
    
    return (
        <>
        <div className='cart_cont'style={props.cart.length === 1 ? {justifyContent: 'center'} : {}}>
            {props.cart.sort((a, b) => (a.title > b.title ? 1 : -1)).map((article) => { //affiche le panier par ordre alphabétique 
                return (
                    <div 
                    key={article.id} 
                    className={quantity === 0 ? 'display_none' : 'cart_article_block'}
                    style={location.pathname === '/user-account' ? {width: '40%'} : null}
                    >
                        <div className='cart_article'>
                            <div className='cart_article_card'>
                                <img  
                                className='img_def' 
                                src={
                                    article.id === '647ef364573064f42bf0c789' ? pot_printemps :
                                    article.id === '64b6aeb8e8fa63aded29eed6' ? pot_tilleul : pot_ete  
                                }  
                                alt="pot de miel" 
                                />
                            </div>
                            <div className='cart_article_properties'>
                                <span className='cart_article_title'>{article.title}</span>
                                <span className='cart_article_price'>{article.price} €</span>
                            </div>
                        </div>
                        <div className='cart_user_choice'>
                            <div className='user_choice_block'>
                                <div className='cart_quantity_choice'>
                                    {(article.quantity <= 1 || location.pathname === '/cart/checkout') ? 
                                        <button disabled className='cart_minus'>-</button> : 
                                        <button className='cart_minus' onClick={() => DecreaseQuantity(article.title)}>-</button>
                                    }
                                    <label>
                                        <input
                                        className="cart_quantity_input"
                                        aria-label='quantité'
                                        type="number"
                                        name='cart_quantity_input'
                                        readOnly
                                        value={article.quantity}
                                        />
                                    </label>
                                    {location.pathname === '/cart/checkout' ?
                                        <button disabled className='cart_plus'>+</button> :
                                        <button className='cart_plus' onClick={() => IncreaseQuantity(article.title)}>+</button>
                                    }
                                </div>
                                <div className='user_choice_total'>{article.price*article.quantity} €</div>
                            </div>
                            {location.pathname === '/cart/checkout'?
                                <button disabled className='trash_button' aria-label='supprimer'><i className="fa-solid fa-trash"></i></button> :
                                <button className='trash_button' onClick={() => deleteArticle(article.title)} aria-label='supprimer'>
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            }
                        </div>
                        <div className='separate_block'>
                            <div className='separate' style={{marginTop: '50px'}}></div>
                        </div>
                    </div>
                )
            })}
        </div>
        <p className={quantity === '0' ? 'empty_cart' : 'display_none'}>Votre panier est vide</p>
        <div className='cart_order'>
            <div className='order_summary'>
                <div className='order_summary_article'>
                    <span className='summary_article_quantity'>{quantity} article(s) :</span>
                    <span className='summary_article_price'>{total} €</span>
                </div>
                <div className='order_delivery'>
                    <span className='order_delivery_text'>Livraison :</span>
                    <span className='order_delivery_price'>{deliveryDisplay()} €
                    </span>
                </div>
            </div>
            <div className='separate_block'>
                <div className='separate'></div>
            </div>
            <div className='order_total'>
                <span className='total_text'>Total :</span>
                <span className='total_price'>{totalDisplay()} €
                </span>
            </div>
        </div>
        </>
    )
}

export default CartLogic