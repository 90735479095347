// affiche les factures utilisateurs
import BillComp from '../components/BillComp';
import Breadcrumb from '../components/Breadcrumb';

function Receipt() {
  	return (
		<>
		<Breadcrumb 
		dest_name1='Mon compte'
		dest_link1='/user-account'
		dest_name2='Mes factures'
		style_link2={{fontWeight: 'bold'}}
		gt2={{display: 'none'}}
		/>
		<BillComp />
		</>
  	)
}

export default Receipt