// oriente la navigation
import { HashLink as Link } from 'react-router-hash-link';
import '../style/Breadcrumb.css';

function Breadcrumb(props) {
    const {
        dest_link1, 
        dest_link2, 
        dest_link3, 
        dest_name1, 
        dest_name2, 
        dest_name3,
        style_link1,
        style_link2,
        style_link3,
        gt1,
        gt2 
    } = props;

  	return (
		<div className='navigation_container'>
			<Link className='link_effect link_underline' to='/'><span>Accueil</span></Link>
			<span className="navigation-arrow">&gt;&gt;</span>
			<Link className='link_effect link_underline' aria-label='lien' style={style_link1} to={dest_link1}><span>{dest_name1}</span></Link>
			<span style={gt1} className="navigation-arrow">&gt;&gt;</span>
			<Link className='link_effect link_underline' aria-label='lien' style={style_link2} to={dest_link2}><span>{dest_name2}</span></Link>
			<span style={gt2} className="navigation-arrow">&gt;&gt;</span>
			<Link className='link_effect link_underline' aria-label='lien' style={style_link3} to={dest_link3}><span>{dest_name3}</span></Link>
		</div>
  	)
}

export default Breadcrumb