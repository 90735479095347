// gère la logique des commandes en cours, composant des pages History et UserAccount
import Toggle from './Toggle';
import '../style/History.css';
import { useState } from 'react';
import Loader from './Loader';
import UseFetch from './UseFetch'
import pot_printemps from '../assets/printemps.jpg';
import pot_tilleul from '../assets/tilleul.jpg';
import pot_ete from '../assets/ete.jpg';

function History_comp() {
	const [isOpen1, setIsOpen1] = useState(false);
	const [isOpen2, setIsOpen2] = useState(false);
	
	const { apiData, isFetched, isError } = UseFetch(`${process.env.REACT_APP_SERVER_URL}/api/order/get`);
	//retourne les deux dernières commandes en cours
	const lastOrdersInProgress = !apiData ? '' : apiData.filter((order) => order.status === "en cours").slice(-2).reverse();

	function convertNumber(element, deliveryPrice) {
		const replaced = [element].toString().replace('.', ',');
		const condition = deliveryPrice === 7.6 || deliveryPrice === 6.9 || deliveryPrice === 10.5;
		const converted = condition ? replaced + 0 : replaced;
		return converted;
	}
	
	return (
		<div id="history_container" className='g_layout'>
			<h1 className="history_h1 h1_desk_fontSize">Commandes en cours</h1>
			{isError === true ? <div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div> : 
			isFetched === false ? <Loader /> :
				(isFetched === true && apiData.length === 0) || (isFetched === true && lastOrdersInProgress.length === 0) ? 
				<div className='no_account' style={{marginTop: '50px', color: 'white'}}>Vous n'avez pas de commandes en cours</div> :
				<div className='history_block'>
					<Toggle
					isOpen={isOpen1}
					setIsOpen={setIsOpen1}
					handleClick={() => {
						isOpen1 === false ? setIsOpen1(true) : setIsOpen1(false); 
						setIsOpen2(false); 
					}}
					title={new Date(lastOrdersInProgress[0].createdAt).toLocaleDateString()}
					content={
						<>
						<div className='history_cont' key={lastOrdersInProgress[0]._id}>
							<div className='history_order_number'>
								<p>{`Commande n° ${lastOrdersInProgress[0].invoiceId}`}</p>
							</div>
							<div 
							className='history_article_container' 
							style={lastOrdersInProgress[0].articles.length === 1 ? {justifyContent: 'center'} : {}}
							>
								{lastOrdersInProgress[0].articles.map((articles) => {
									return (
										<div key={articles._id} className='history_article_block'>
											<div className='history_article'>
												<div className='history_article_card'>
													<img  
													className='img_def' 
													src={
														articles._id === '647ef364573064f42bf0c789' ? pot_printemps :
														articles._id === '64b6aeb8e8fa63aded29eed6' ? pot_tilleul : pot_ete  
													}  
													alt="pot de miel" 
													/>
												</div>
												<div className='history_apiData_properties'>
													<span className='history_article_item'>{articles.title}</span>
													<span className='history_article_item'>{articles.price} €</span>
													<span className='history_article_item'>quantité : {articles.quantity}</span>
												</div>
											</div>
											<div className='separate_block'>
												<div className='separate' style={{marginTop: '30px'}}></div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='history_order'>
								<div className='history_order_summary'>
									<div className='history_order_art'>
										<span className='history_summary_art'>Total article(s) :</span>
										<span className='history_summary_price'>{lastOrdersInProgress[0].total} €</span>
									</div>
									<div className='history_order_delivery'>
										<span className='history_order_text'>Livraison :</span>
										<span className='history_delivery_price'>
											{convertNumber(lastOrdersInProgress[0].deliveryPrice, lastOrdersInProgress[0].deliveryPrice)} €
										</span>
									</div>
								</div>
								<div className='separate_block'>
									<div className='separate'></div>
								</div>
								<div className='history_order_total'>
									<span className='history_total_text'>Total :</span>
									<span className='history_total_price'>
										{convertNumber(lastOrdersInProgress[0].totalTtc, lastOrdersInProgress[0].deliveryPrice)} €
									</span>
								</div>
							</div>
						</div>
						</>				
					}/>
					{!lastOrdersInProgress[1] ? '' :
						<Toggle
						isOpen={isOpen2}
						setIsOpen={setIsOpen2}
						handleClick={() => {
							isOpen2 === false ? setIsOpen2(true) : setIsOpen2(false); 
							setIsOpen1(false); 
						}}
						title={new Date(lastOrdersInProgress[1].createdAt).toLocaleDateString()}
						content={
							<>
							<div className='history_cont' key={lastOrdersInProgress[1]._id}>
								<div className='history_order_number'>
									<p>{`Commande n° ${lastOrdersInProgress[1].invoiceId}`}</p>
								</div>
								<div 
								className='history_article_container' 
								style={lastOrdersInProgress[0].articles.length === 1 ? {justifyContent: 'center'} : {}}
								>
									{lastOrdersInProgress[1].articles.map((articles) => {
										return (
											<div key={articles._id} className='history_article_block'>
												<div className='history_article'>
													<div className='history_article_card'>
														<img  
														className='img_def' 
														src={
															articles._id === '647ef364573064f42bf0c789' ? pot_printemps :
															articles._id === '64b6aeb8e8fa63aded29eed6' ? pot_tilleul : pot_ete  
														}  
														alt="pot de miel" 
														/>
													</div>
													<div className='history_apiData_properties'>
														<span className='history_article_item'>{articles.title}</span>
														<span className='history_article_item'>{articles.price} €</span>
														<span className='history_article_item'>quantité : {articles.quantity}</span>
													</div>
												</div>
												<div className='separate_block'>
													<div className='separate' style={{marginTop: '30px'}}></div>
												</div>
											</div>
										)
									})}
								</div>
								<div className='history_order'>
									<div className='history_order_summary'>
										<div className='history_order_art'>
											<span className='history_summary_art'>total :</span>
											<span className='history_summary_price'>{lastOrdersInProgress[1].total} €</span>
										</div>
										<div className='history_order_delivery'>
											<span className='history_order_text'>Livraison :</span>
											<span className='history_delivery_price'>
												{convertNumber(lastOrdersInProgress[1].deliveryPrice, lastOrdersInProgress[1].deliveryPrice)} €
											</span>
										</div>
									</div>
									<div className='separate_block'>
										<div className='separate'></div>
									</div>
									<div className='history_order_total'>
										<span className='history_total_text'>Total T.T.C :</span>
										<span className='history_total_price'>
											{convertNumber(lastOrdersInProgress[1].totalTtc, lastOrdersInProgress[1].deliveryPrice)} €
										</span>
									</div>
								</div>
							</div>
							</>				
						}/>
					}
				</div>
			}
		</div>
	)
}

export default History_comp