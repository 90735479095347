// logique de connexion
import { HashLink as Link } from 'react-router-hash-link';
import Form from "../components/Form";
import Breadcrumb from '../components/Breadcrumb';
import '../style/Login.css';

function Login({ isAuth, setIsAuth }) {
    return (
        <>
            <Breadcrumb
                dest_name1='Connexion'
                style_link1={{ fontWeight: 'bold' }}
                gt1={{ display: 'none' }}
                gt2={{ display: 'none' }}
            />
            <div id="login_container" className="g_layout logSign_container">
                <h1 className="login_title h1_desk_fontSize">Votre compte</h1>
                <h2 className="subtitle">Déja inscrit ?</h2>
                <Form
                    id='login_form'
                    textButton='Connexion'
                    url={`${process.env.REACT_APP_SERVER_URL}/api/auth/login`}
                    method='POST'
                    navUrl='/'
                    errorContent={'Email ou mot de passe non reconnu / erreur serveur'}
                    styleCivility={{ display: 'none' }}
                    styleFirstName={{ display: 'none' }}
                    styleLastName={{ display: 'none' }}
                    styleAddress={{ display: 'none' }}
                    styleDelivery={{ display: 'none' }}
                    styleConfirm={{ display: 'none' }}
                    styleCity={{ display: 'none' }}
                    stylePostCode={{ display: 'none' }}
                    styleAdditional={{ display: 'none' }}
                    stylePhone={{ display: 'none' }}
                    styleOldPassword={{ display: 'none' }}
                    styleList={{ display: 'none' }}
                    styleTitle={{ display: 'none' }}
                    requiredEmail={true}
                    requiredPassword={true}
                    isAuth={isAuth}
                    setIsAuth={setIsAuth}
                />
                <Link to='/ForgotPassword#forgot_container' className="forget_link link_effect">Mot de passe oublié</Link>
                <div className='separate_block'>
                    <div className="separate sep_log"></div>
                </div>
                <div className="signUp_block">
                    <h2 className="subtitle sub2">Pas encore inscrit ?</h2>
                    <Link to='/signup#signup_container'>
                        <button className="login_btn">
                            Créer un compte
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Login