// gère la logique des coordonnées utilisateur, importé sur les pages Personnal et UserAccount
import { HashLink as Link } from 'react-router-hash-link';
import UseFetch from './UseFetch';
import Loader from './Loader';
import '../style/Personnal.css';
import UserInfo from './UserInfo';

function PersonnalComp() {
	const { apiData, isFetched, isError } = UseFetch(`${process.env.REACT_APP_SERVER_URL}/api/userData/getOne`);

  	return (
		<div id='personnal_container' className='g_layout'>
			<h1 className='personnal_h1 h1_desk_fontSize'>Mon profil</h1>
			{isError === true ? <div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div> : 
			isFetched === false ? <Loader /> : 
				apiData === null && isFetched === true ? 
				<><div className='no_account'>Vous n'avez pas encore enregistré de coordonnées</div>
					<div style={{ textAlign: 'center' }}>
					<Link to={'/user-account/registration'}>
						<button className='login_btn'>
							S'enregistrer
						</button>
					</Link>
				</div></> :
				<div className='personnal_profil_block'>
					<UserInfo 
					civility={apiData.civility}
					firstName={apiData.firstName} 
					lastName={apiData.lastName}
					address={apiData.address} 
					additional={apiData.additional}
					postCode={apiData.postCode} 
					city={apiData.city} 
					/>
					<div className='personnal_display'>
						<i className="fa-solid fa-mobile-screen pers_icon"></i>
						<span>{apiData.phone}</span>
						<div className='separate_block'>
							<div className='separate sep_pers'></div>
						</div> 
					</div>
					<Link to={`/user-account/modify/${apiData._id}#modify_container`}>
						<button className="login_btn ">
							Modifier
						</button>
					</Link>
				</div>
			}
		</div>
  	)
}

export default PersonnalComp