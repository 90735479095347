import '../style/Loader.css';

function Loader() {
  return (
    <div className='loader_div'>
        <span className='loader'></span>
    </div>
  )
}

export default Loader