// enregistre les adresses secondaires(livraison) de l'utilisateur.
import { useLocation } from 'react-router-dom';
import Form from '../components/Form';
import UseFetch from '../components/UseFetch';
import Loader from '../components/Loader';
import Breadcrumb from '../components/Breadcrumb';
import '../style/AddressReg.css';

function AddressReg() {
    
    const { apiData, isFetched, isError } = UseFetch(`${process.env.REACT_APP_SERVER_URL}/api/address/getAll`);

    const location = useLocation();
    const navUrl = location.state === null ? '/user-account' : location.state.previousPage;
    
    return (
        <>
        <Breadcrumb 
        dest_name1='Mon compte'
        dest_link1='/user-account'
        dest_name2='Ajouter une adresse'
		style_link2={{fontWeight: 'bold'}}
        gt2={{display: 'none'}}
        />
        <div id="addressReg_container" className='g_layout'>
        {isError === true ? <div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div> : 
        isFetched === false ? <Loader /> :
            apiData.length === 3 ? 
			<p>Désolé, vous avez atteint la limite d'enregistrement d'adresse...</p> :
                <div className="addressReg_block">
                    <h1 className='addressReg_h1 h1_desk_fontSize'>Ajouter une adresse</h1>
                    <Form
                    id= 'addressReg_form'
                    textButton= 'Enregistrer'
                    url={`${process.env.REACT_APP_SERVER_URL}/api/address`}
                    method='POST'
                    navUrl={navUrl}
                    styleConfirm={{display:'none'}}
                    styleEmail={{display:'none'}}
                    stylePassword={{display:'none'}}
                    styleOldPassword={{display:'none'}}
                    stylePhone={{display:'none'}}
                    requiredPassword={false}
                    requiredEmail={false}
                    requiredTitle={true}
                    requiredCivility={true}
                    requiredFirstName={true}
                    requiredLastName={true}
                    requiredAddress={true}
                    requiredCity={true}
                    requiredPostCode={true}
                    placeholderAdditional= 'optionnel'
                    placeholderTitle= 'Adresse2, ...'
                    errorContent={"Une erreur s'est produite sur le serveur"}
                    />
                </div>
            }
        </div>
        </>
    )
}

export default AddressReg