// Pour choisir l'adresse de livraison dans le tunnel de paiement. Composant de la page Checkout
import { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Loader from "./Loader";
import DeliveryButton from "./DeliveryButton";
import '../style/DeliveryAddress.css'

function DeliveryAddress(props) {
    // eslint-disable-next-line no-unused-vars
    const [showButton, setShowButton] = useState(false);
    const [showIcon, setShowIcon] = useState(false);
    const [showIcon1, setShowIcon1] = useState(false);
    const [showIcon2, setShowIcon2] = useState(false);
    const [showIcon3, setShowIcon3] = useState(false);

    const [apiData, setApiData] = useState();
    const [apiData2, setApiData2] = useState();
    const [isFetched, setIsFetched] = useState(false);
    const [isError, setIsError] = useState(false);

    const checkoutLinkState = {
        previousPage: '/cart/checkout#order_container'
    }

    const isAddressBtn = true;

    function setChoice(id) {
        setShowButton(true);  
        props.setDeliveryAddress({id: id});
    }

    useEffect(() => {
        async function getAllAddresses() {
            setApiData(null);
            await fetch(`${process.env.REACT_APP_SERVER_URL}/api/userData/getOne`, {
                method: 'GET',
                headers : {
                    'Content-type': 'application/json'
                },
                credentials: 'include'
            })
            .then(res => res.json())
            .then(data => setApiData(data))
            .catch(() => setIsError(true))
            
            setApiData2(null);
            await fetch(`${process.env.REACT_APP_SERVER_URL}/api/address/getAll`, {
                method: 'GET',
                headers : {
                    'Content-type': 'application/json'
                },
                credentials: 'include'
            })
            .then(res => res.json())
            .then(data => setApiData2(data))
            .catch(() => setIsError(true))
            .finally(() => setIsFetched(true));
        }
        getAllAddresses();
    }, [])

    if(isError === true)  {
		return <div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div>
	} else {
        return (
            <div id='deliveryA_container' className='g_layout'>
                {isFetched === false ? <Loader /> : 
                    (apiData && apiData2) === null && isFetched === true ? '':
                    <>
                    <div className='deliveryA_address_container' 
                    style={window.innerWidth >767 && apiData2.length <1 ? {justifyContent: 'center'} : 
                        window.innerWidth >991 && apiData2.length >2 ? {justifyContent: 'space-between'} : {}
                    }
                    >
                        <DeliveryButton 
                        handleClick={() => {
                            setChoice(apiData._id); 
                            setShowIcon(!showIcon)
                            setShowIcon1(false)
                            setShowIcon2(false)
                            setShowIcon3(false)
                        }} 
                        title={'Adresse principale'} 
                        isAddressBtn={isAddressBtn}
                        civility={apiData.civility}
                        firstName={apiData.firstName}
                        lastName={apiData.lastName}
                        address={apiData.address}
                        additional={apiData.additional}
                        postCode={apiData.postCode}
                        city={apiData.city}
                        showIcon={showIcon}
                        />
                        {apiData2.length >0 ?
                            <DeliveryButton 
                            handleClick={() => {
                                setChoice(apiData2[0]._id); 
                                setShowIcon1(!showIcon1)
                                setShowIcon(false)
                                setShowIcon2(false)
                                setShowIcon3(false)
                            }} 
                            title={apiData2[0].title} 
                            isAddressBtn={isAddressBtn}
                            civility={apiData2[0].civility}
                            firstName={apiData2[0].firstName}
                            lastName={apiData2[0].lastName}
                            address={apiData2[0].address}
                            additional={apiData2[0].additional}
                            postCode={apiData2[0].postCode}
                            city={apiData2[0].city}
                            showIcon={showIcon1}
                            /> : <></>
                        }
                        {apiData2.length >1 ?
                            <DeliveryButton 
                            handleClick={() => {
                                setChoice(apiData2[1]._id); 
                                setShowIcon2(!showIcon2)
                                setShowIcon(false)
                                setShowIcon1(false)
                                setShowIcon3(false)
                            }} 
                            title={apiData2[1].title} 
                            isAddressBtn={isAddressBtn}
                            civility={apiData2[1].civility}
                            firstName={apiData2[1].firstName}
                            lastName={apiData2[1].lastName}
                            address={apiData2[1].address}
                            additional={apiData2[1].additional}
                            postCode={apiData2[1].postCode}
                            city={apiData2[1].city}
                            showIcon={showIcon2}
                            /> : <></>
                        }
                        {apiData2.length >2 ?
                            <DeliveryButton 
                            handleClick={() => {
                                setChoice(apiData2[2]); 
                                setShowIcon3(!showIcon3)
                                setShowIcon(false)
                                setShowIcon1(false)
                                setShowIcon2(false)
                            }} 
                            
                            title={apiData2[2].title} 
                            isAddressBtn={isAddressBtn}
                            civility={apiData2[2].civility}
                            firstName={apiData2[2].firstName}
                            lastName={apiData2[2].lastName}
                            address={apiData2[2].address}
                            additional={apiData2[2].additional}
                            postCode={apiData2[2].postCode}
                            city={apiData2[2].city}
                            showIcon={showIcon3} 
                            /> : <></> 
                        }
                    </div>
                    <div style={{textAlign: 'center', marginTop: '20px', marginBottom: '40px'}}>
                        <Link 
                        to='/user-account/registration/addressReg#addressReg_container' 
                        state={checkoutLinkState} 
                        className='deliveryA_link link_effect link_underline'
                        >
                            Choisir une autre adresse de livraison
                        </Link>
                    </div>
                    <div className="deliveryA_btn">
                        <Link smooth to='#toggle_link_landmark'>
                            <button 
                            style={{marginBottom:'50px'}} 
                            className={
                                (showButton === true && showIcon === true) || (showButton === true && showIcon1 === true) || 
                                (showButton === true && showIcon2 === true) || (showButton === true && showIcon3 === true) ? 
                                'login_btn' : 'hide'
                            } 
                            onClick={() => {
                                props.setIsAddress(true);
                                props.setIsOpen2(false);
                                props.setIsOpen3(true);
                            }}
                            >
                                Valider
                            </button>
                        </Link>
                    </div>
                </> 
                }
            </div>
        )
    }
}

export default DeliveryAddress