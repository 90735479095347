// compose les champs avec un select, importé sur Form
import React from 'react'

function FormSelect(props) {
    const {
        text, 
        handleChange,
        className,
        name,
        value1,
        value2,
        invalid,
        required,
        style,
    }= props;

    return (
        <div className="form_group" style={style}>  
            <label htmlFor="civility" className="form_label">{text}</label>
            <select
            required={required}
            id="civility"
            className={className}
            name={name}
            onChange={handleChange}
            >
                <option></option>
                <option name='mister' value1={value1}>Monsieur</option>
                <option name='miss' value2={value2}>Madame</option>	
            </select>
            <div className="invalid_feedback">
                {invalid}
            </div>
        </div>
    )
}

export default FormSelect