// modifie les adresses secondaires(livraison) de l'utilisateur. 
import Form from '../components/Form';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import '../style/AddressMod.css';

function AddressMod({ isAuth, setIsAuth }) {
	const urlParams = useParams();
	
  return (
	<>
	<Breadcrumb 
        dest_name1='Mon compte'
        dest_link1='/user-account'
        dest_name2='Modifier une adresse'
		style_link2={{fontWeight: 'bold'}}
		gt2={{display: 'none'}}
        />
    <div id='AddressMod_container' className='g_layout'>
		<h1 className='AddressMod_h1 h1_desk_fontSize'>Modifier une adresse</h1>
        <Form
        id='AddressMod_form'
		textButton='Enregistrer'
		url={`${process.env.REACT_APP_SERVER_URL}/api/address/${urlParams.id}`}
		navUrl='/user-account'
		method='PUT'
		styleConfirm={{display:'none'}}
		stylePassword={{display:'none'}}
		styleDelivery={{display:'none'}}
		styleEmail={{display:'none'}}
		styleOldPassword={{display:'none'}}
		styleDeliveryFirstName={{display:'none'}}
		styleDeliveryLastName={{display:'none'}}
		styleDeliveryAddress={{display:'none'}}
		styleDeliveryAdditional={{display:'none'}}
		styleDeliveryPostCode={{display:'none'}}
		styleDeliveryCity={{display:'none'}}
        stylePhone={{display:'none'}}
		requiredTitle={true}
		requiredCivility={true}
		requiredLastName={true}
		requiredFirstName={true}
		requiredAddress={true}
		requiredPostCode={true}
		requiredCity={true}
		placeholderAdditional= 'optionnel'
		placeholderTitle= 'Adresse1, ...'
		isAuth={isAuth}
		setIsAuth={setIsAuth}
		errorContent={"Une erreur s'est produite sur le serveur"}
        />
    </div>
	</>
  )
}

export default AddressMod