// page d'annulation de paiement stripe
import { HashLink as Link } from 'react-router-hash-link';

function Cancel() {
	return (
		<div id='success_container' className='g_layout'>
			<h1>Votre commande a été annulée</h1>
			<p>Des questions sur le module de paiement ? Contactez-nous</p>
			<div style={{textAlign: 'center'}}>
				<Link className='link_effect link_underline' style={{width: 'max-content'}} to='/#home_container'>
					Retour à l'accueil
				</Link>
			</div>
			
		</div>
  	)
}

export default Cancel