// requête pour récupérer le lien de paiement sur stripe.com, importée sur composants DeliveryMode, DeliveryRetirement, deliveryPointResult
export const addStripeParams = async(propsCart, propsDelChoice, setApiData) => {
    const lineItems = propsCart.map((element) => { // données exigées pour le module Stripe
        return {
            price_id: element.price_id,
            quantity: element.quantity,
        }
    });

    await fetch(`${process.env.REACT_APP_SERVER_URL}/api/checkout`, {   // fetch sur l'api de Stripe
        method: 'POST',
        body: JSON.stringify({
            line_items: lineItems,
            deliveryTitle: propsDelChoice.title, // mode de livraison
            deliveryPrice: propsDelChoice.price
        }),
        headers: {
            'Content-type': 'application/json'
        },
        credentials: 'include'	
    })
    .then(res => res.json())
    .then(data => setApiData(data)) // renvoie l'url de la page de paiement de Stripe
    .catch(err => console.log(err));  
}