import'../style/Footer.css';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import logo from '../assets/logo_nouv.png';
import colissimo from '../assets/Colissimo-white-20-11.jpg';
import allPaiements from '../assets/5e8ad2a6bb92-1.jpg'
import stripeLogo from '../assets/StripeWordmark-blurple-small.jpg'

function Footer() {
    const location = useLocation(); 

    return (
        location.pathname === '/cart/checkout/success' ? <div></div> :
        <div className='footer' id='footer_container'>
            <div className='footer_block1'>
                <div className='footer_pay_logo'>
                    <div>
                        <img src={allPaiements} alt="logos masterCard, americanExpress, visa et CB" className='pay_logo' />
                    </div>
                    <div className='strip_coli_block'>
                        <img src={stripeLogo} alt="logo stripe" className='stripe_logo' />
                        <img src={colissimo} alt="logo Colissimo" className='coli_logo' />
                    </div>
                </div>
            </div>
            <div className='footer_container'>
                <div className='footer_block footer_block1'>
                    <h3 id='h3_info' className='h3_footer'>Informations</h3>  
                    <ul className='footer_list'>
                        <li className='footer_item link_effect'>
                            <Link to='/info#cgv_block' className='footer_link'>Conditions générales de vente</Link>
                        </li>
                        <li className='footer_item link_effect'>
                            <Link to='/info#siteMap' className='footer_link'>Plan du site</Link>
                        </li>
                    </ul>
                    <div className='foot_sep_block'>
                        <div className='footer_separate'></div>
                    </div> 
                </div>
                <div className='footer_block'>
                    <h3 id='h3_about' className='h3_footer'>A propos</h3>
                    <ul className='footer_list'>
                        <li className='footer_item link_effect'>
                            <Link to='/info#leg_block' className='footer_link'>Mentions légales</Link>
                        </li>
                    </ul>
                    <div className='foot_sep_block'>
                        <div className='footer_separate'></div>
                    </div>
                </div>
                <div className='footer_block'>
                    <h3 id='h3_contact ' className='h3_footer'>Contact</h3>
                    <div className='footer_logo_block'>
                        <img src={logo} alt="logo entreprise" className='logo_footer img_def'/>
                    </div>
                    <ul className='footer_list'>
                        <li>Le Rucher des Albizias</li>
                        <li>15 rue du Pic du Ger</li>
                        <li>64230 DENGUIN</li>
                        <li className='mobile_li'>contact.lerucherdesalbizias@gmail.com</li>
                    </ul>
                    <div className='foot_sep_block'>
                        <div className='footer_separate'></div>
                    </div>
                </div>
            </div>
            <div className='footer_rights'>
                <p>© 2023 Le Rucher des Albizias - Copyright</p>
            </div>
        </div>
    ) 
}

export default Footer