// logique de recherche de points relais/retrait. Importé sur le composant deliveryRetirement
import { useState } from 'react';
import Loader from './Loader';
import FormInput from './FormInput';
import DeliveryPointResult from './DeliveryPointResult';
import '../style/DeliveryPoint.css';

function DeliveryPointSearch(props) {
    const [apiDataXml, setApiDataXml] = useState(null);
    const [isFetched, setIsFetched] = useState();
    const [isError, setIsError] = useState(false); 

    // données des points retraits/relais
    const names = [];
    const addresses = [];
    const postCodes = [];
    const cities = [];
    const distances = []; // distance en mètre adresse/point
    const mondayHours = []; // horaires d'ouverture
    const tuesdayHours = [];
    const wednesdayHours = [];
    const thursdayHours = [];
    const fridayHours = [];
    const saturdayHours = [];
    const sundayHours = [];
    const leaves = [];   // éventuels congés

    const points = [];   // points de retrait et relais

    const myLogin = process.env.REACT_APP_MY_LOGIN;
    const myPassword = process.env.REACT_APP_MY_PASSWORD;

    const today = new Date();
    const nextDay = new Date();
    nextDay.setDate(today.getDate()+4);
    const deliveryDate = nextDay.toLocaleDateString() // définit la date de livraison à j+4 dans l'url

    const numberForRetirement = // définit la recherche de points relais ou retrait dans l'url
        props.isDeliveryRetirement === true ? 0 : props.isDeliveryRelay === true ? 2 : '';

    const [myForm, setMyForm] = useState({
        formValues: {
			address: '',
			postCode: '',
			city: '',
        },
        formErrors: {
			address: '',
			postCode: '',
			city: '',
        },
        formValidity: {
			address: true,
			postCode: true,
			city: true,
        }
    })

    const handleChange = ({ target }) => {  
        const { formValues } = myForm;      
        formValues[target.name] = target.value;
        setMyForm({ formValues });
        handleValidation(target);
    }

    const handleValidation = (target) => {    
        const { name, value } = target;		
		        
        const fieldErrors = myForm.formErrors;
        const validity = myForm.formValidity;
		
		const isAddress = name === 'address';
		const isPostCode = name === 'postCode';
		const isCity = name === 'city';

		const addressTest = /^[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ0-9 \-']{1,45}$/i;
		const postCodeTest = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/i;
		const cityTest = /^[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ0-9 \-']{1,45}$/i;

		if (isAddress) {
			validity[name] = addressTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Cette adresse n'est pas valide`;
		}
		
		if (isPostCode) {
			validity[name] = postCodeTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Ce code postal n'est pas valide`;
		}
		if (isCity) {
			validity[name] = cityTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Cette ville n'est pas valide`;
		}
        
        setMyForm({
            ...myForm,
            formErrors: fieldErrors,
            formValidity: validity,
        });
    }

    function handleSubmit(event) { 
        event.preventDefault();
        const { formValues, formValidity } = myForm;
        
        if (Object.values(formValidity).every(Boolean)) {	
            function fetchXml() {
                const data = this.responseText
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data, 'text/xml');
                setApiDataXml(xmlDoc);
            }
			
            function error() {
                setIsError(true);
            }
            const address = formValues.address;
            const postCode = formValues.postCode;
            const city = formValues.city;

            setIsFetched(true);
            const xhr = new XMLHttpRequest();
            xhr.onload = fetchXml;
            xhr.onerror = error;
    
            // paramètres url: myLogin, myPassword = identifiants compte Colisimo, shippingDate = date de livraison estimée, 
            // filterRelay 0 = postes uniquement 1 = postes et commerces  2 = commerces uniquement 
            xhr.open('GET', `https://ws.colissimo.fr/pointretrait-ws-cxf/PointRetraitServiceWS/2.0/findRDVPointRetraitAcheminement?accountNumber=${myLogin}&password=${myPassword}&address=${address}&zipCode=${postCode}&city=${city}&countryCode=FR&shippingDate=${deliveryDate}&filterRelay=${numberForRetirement}`);
            xhr.send();

            setIsFetched(false);
            document.getElementById('landmark').scrollIntoView({ behavior: 'smooth' });
        }
	}

    if(isFetched === false && apiDataXml) {
        const namesCollection = apiDataXml.getElementsByTagName("nom");
        const addressCollection = apiDataXml.getElementsByTagName("adresse1");
        const postCodesCollection = apiDataXml.getElementsByTagName("codePostal");
        const citiesCollection = apiDataXml.getElementsByTagName("localite");
        const distancesCollection = apiDataXml.getElementsByTagName("distanceEnMetre");
        const mondayHoursCollection = apiDataXml.getElementsByTagName("horairesOuvertureLundi");
        const tuesdayHoursCollection = apiDataXml.getElementsByTagName("horairesOuvertureMardi");
        const wednesdayHoursCollection = apiDataXml.getElementsByTagName("horairesOuvertureMercredi");
        const thursdayHoursCollection = apiDataXml.getElementsByTagName("horairesOuvertureJeudi");
        const fridayHoursCollection = apiDataXml.getElementsByTagName("horairesOuvertureVendredi");
        const saturdayHoursCollection = apiDataXml.getElementsByTagName("horairesOuvertureSamedi");
        const sundayHoursCollection = apiDataXml.getElementsByTagName("horairesOuvertureDimanche");
        const totalLeaveCollection = apiDataXml.getElementsByTagName("congesTotal");

        function loopCollection(collection, newArray) {
            for(let element of collection) {
                newArray.push(element.textContent);
            }
        }

        function loopHoursCollection(collection, newArray) {
            for(let element of collection) {
                newArray.push(element.textContent);
            }
        }

        loopCollection(namesCollection, names);
        loopCollection(addressCollection, addresses);
        loopCollection(postCodesCollection, postCodes);
        loopCollection(citiesCollection, cities);
        loopCollection(distancesCollection, distances);
        loopCollection(totalLeaveCollection, leaves);
        loopHoursCollection(mondayHoursCollection, mondayHours);
        loopHoursCollection(tuesdayHoursCollection, tuesdayHours);
        loopHoursCollection(wednesdayHoursCollection, wednesdayHours);
        loopHoursCollection(thursdayHoursCollection, thursdayHours);
        loopHoursCollection(fridayHoursCollection, fridayHours);
        loopHoursCollection(saturdayHoursCollection, saturdayHours);
        loopHoursCollection(sundayHoursCollection, sundayHours);

        points.push(
            [
                names[0], addresses[0], postCodes[0], cities[0], distances[0], mondayHours[0], tuesdayHours[0], wednesdayHours[0], 
                thursdayHours[0], fridayHours[0], saturdayHours[0], sundayHours[0], leaves[0]
            ], 
            [
                names[1], addresses[1], postCodes[1], cities[1], distances[1], mondayHours[1], tuesdayHours[1], wednesdayHours[1],
                thursdayHours[1], fridayHours[1], saturdayHours[1], sundayHours[1], leaves[1] 
            ], 
            [
                names[2], addresses[2], postCodes[2], cities[2], distances[2], mondayHours[2], tuesdayHours[2], wednesdayHours[2],
                thursdayHours[2], fridayHours[2], saturdayHours[2], sundayHours[2], leaves[2] 
            ], 
            [
                names[3], addresses[3], postCodes[3], cities[3], distances[3], mondayHours[3], tuesdayHours[3], wednesdayHours[3],
                thursdayHours[3], fridayHours[3], saturdayHours[3], sundayHours[3], leaves[3]
            ], 
            [
                names[4], addresses[4], postCodes[4], cities[4], distances[4], mondayHours[4], tuesdayHours[4], wednesdayHours[4],
                thursdayHours[4], fridayHours[4], saturdayHours[4], sundayHours[4], leaves[4] 
            ], 
            [
                names[5], addresses[5], postCodes[5], cities[5], distances[5], mondayHours[5], tuesdayHours[5], wednesdayHours[5],
                thursdayHours[5], fridayHours[5], saturdayHours[5], sundayHours[5], leaves[5] 
            ],
            [
                names[6], addresses[6], postCodes[6], cities[6], distances[6], mondayHours[6], tuesdayHours[6], wednesdayHours[6],
                thursdayHours[6], fridayHours[6], saturdayHours[6], sundayHours[6], leaves[6] 
            ],
            [
                names[7], addresses[7], postCodes[7], cities[7], distances[7], mondayHours[7], tuesdayHours[7], wednesdayHours[7],
                thursdayHours[7], fridayHours[7], saturdayHours[7], sundayHours[7], leaves[7] 
            ],
            [
                names[8], addresses[8], postCodes[8], cities[8], distances[8], mondayHours[8], tuesdayHours[8], wednesdayHours[8],
                thursdayHours[8], fridayHours[8], saturdayHours[8], sundayHours[8], leaves[8]
            ],
            [
                names[9], addresses[9], postCodes[9], cities[9], distances[9], mondayHours[9], tuesdayHours[9], wednesdayHours[9],
                thursdayHours[9], fridayHours[9], saturdayHours[9], sundayHours[9], leaves[9]
            ],
            [
                names[10], addresses[10], postCodes[10], cities[10], distances[10], mondayHours[10], tuesdayHours[10], wednesdayHours[10],
                thursdayHours[10], fridayHours[10], saturdayHours[10], sundayHours[10], leaves[10]
            ],
            [
                names[11], addresses[11], postCodes[11], cities[11], distances[11], mondayHours[11], tuesdayHours[11], wednesdayHours[11],
                thursdayHours[11], fridayHours[11], saturdayHours[11], sundayHours[11], leaves[11]
            ],
            [
                names[12], addresses[12], postCodes[12], cities[12], distances[12], mondayHours[12], tuesdayHours[12], wednesdayHours[12],
                thursdayHours[12], fridayHours[12], saturdayHours[12], sundayHours[12], leaves[12]
            ],
            [
                names[13], addresses[13], postCodes[13], cities[13], distances[13], mondayHours[13], tuesdayHours[13], wednesdayHours[13],
                thursdayHours[13], fridayHours[13], saturdayHours[13], sundayHours[13], leaves[13]
            ],
            [
                names[14], addresses[14], postCodes[14], cities[14], distances[14], mondayHours[14], tuesdayHours[14], wednesdayHours[14], 
                thursdayHours[14], fridayHours[14], saturdayHours[14], sundayHours[14], leaves[14]
            ]
        ) 
    }
    
    if(isError === true) {
        return <div className='error_div'><p className='error_message'>Serveur indisponible</p></div>
    } else {
        return (
            <div id='delPoint_container'>
                <div className='delPoint_form_block'>
                    <p>Choisir un autre point {props.isDeliveryRetirement === true ? 'de retrait' : 'relais'} :</p>
                    <form 
                    id='delPoint_form' 
                    className="form_body" 
                    onSubmit={handleSubmit} 
                    onKeyDown={(e) => {e.key === 'Enter' && e.preventDefault();}}
                    >
                        <FormInput
                        required={true} 
                        text={'Adresse'}
                        handleChange={handleChange} 
                        className={myForm.formErrors.address === '' && myForm.formValues.address !== '' ? 'form_input valid' : "form_input"}
                        type='text'
                        name='address'
                        value={myForm.formValues.address}
                        invalid={myForm.formErrors.address}
                        />
                        <FormInput
                        required={true}   
                        text="Code postal"
                        handleChange={handleChange} 
                        className={myForm.formErrors.postCode === '' && myForm.formValues.postCode !== '' ? 'form_input valid' : "form_input"}
                        type='text'
                        name='postCode'
                        value={myForm.formValues.postCode}
                        invalid={myForm.formErrors.postCode}
                        />
                        <FormInput
                        required={true}   
                        text="Ville"
                        handleChange={handleChange} 
                        className={myForm.formErrors.city === '' && myForm.formValues.city !== '' ? 'form_input valid' : "form_input"}
                        type='text'
                        name='city'
                        value={myForm.formValues.city}
                        invalid={myForm.formErrors.city}
                        />
                        <button type="submit" className="login_btn">
                            Rechercher
                        </button> 
                    </form>
                </div>
                <div id='landmark'>
                    {isFetched === true ? <Loader/> : 
                    isError === true ? <p className='error_message'>Le serveur de Colissimo est indisponible</p> :
                        <DeliveryPointResult 
                        points={points} 
                        isDeliveryRetirement={props.isDeliveryRetirement} 
                        isAddress={props.isAddress} 
                        setIsAddress={props.setIsAddress}
                        deliveryPoint={props.deliveryPoint}
                        setDeliveryPoint={props.setDeliveryPoint}
                        isOpen1={props.isOpen1} 
                        setIsOpen1={props.setIsOpen1}
                        isOpen3={props.isOpen3}
                        setIsOpen3={props.setIsOpen3}
                        apiData={props.apiData}
                        setApiData={props.setApiData}
                        cart={props.cart}
                        deliveryChoice={props.deliveryChoice}
                        isfetched={isFetched}
                        apiDataXml={apiDataXml}
                        /> 
                    }
                </div>
            </div>
        )
    }
}

export default DeliveryPointSearch