import '../style/Home.css';
import collage from '../assets/collage2.jpg';
import bannerXL from '../assets/banniere-XL.jpg';
import bannerL from '../assets/banniere-L.jpg';
import bannerS from '../assets/banniere-S.jpg';
import bee_icon from '../assets/rayon-de-miel.png';
import honeys from '../assets/miels3.jpg';
import { HashLink as Link } from 'react-router-hash-link';
import ReactGA from "react-ga4";

function Home_p() {
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "home"
    });

    return (
        <div id='home_container' className='g_layout'>
            <div className='home_banner'>
                <div className='banner_block picture_block'>
                    <picture>
                        <source srcSet={bannerS} media="(max-width: 767px)" />
                        <source srcSet={bannerL} media="(max-width: 991px)" />
                        <source srcSet={bannerXL} media="(min-width: 992px)" />
                        <img fetchpriority="high" src={bannerXL} alt="abeille butinant une fleur de ronce dans le Béarn" className='img_def banner_pict' />
                    </picture>
                </div>
            </div>
            <h1 className='parisienne h1_parisienne home_h1'>Le Rucher des Albizias</h1>
            <div className='home_text_block'>
                <div className='h2_block'>
                    <img src={bee_icon} alt="icone en forme de rayon de miel" className='bee_icon bee_icon1' />
                    <h2 className='home_h2'>Production et vente de miel artisanal</h2>
                    <img src={bee_icon} alt="icone en forme de rayon de miel" className='bee_icon bee_icon2' />
                </div>
                <div className='home_text_picture picture_block' style={window.innerWidth < 992 ? {display: 'none'} : {display: 'block'}}>
                    <img src={honeys} alt="miels du rucher des albizias" className='img_def' />
                </div>
                <div className='home_text_content'>               
                    <p className='home_title'>Le Rucher des Albizias vous propose ses miels :</p>
                    <ul className='home_list'>
                        <li>- Issus d'une apiculture traditionnelle respectueuse de l'environnement,</li>
                        <li>- Extraits à froid pour conserver toutes leurs qualités et leurs saveurs,</li>
                        <li>- Non mélangés, récoltés sans répulsifs chimiques,</li>
                        <li>- Non prélevés dans les corps de ruche, dans le respect du développement de la colonie,</li>
                        <li>- Produits et récoltés près de Pau dans le Béarn.</li>
                    </ul>
                    <div className='separate_block home_separate'>
                        <div className='separate'></div>
                    </div>
                </div>
            </div>
            <div className='home_honeys_block'style={window.innerWidth > 992 ? {display: 'none'} : {display: 'block'}} >
                <div className='home_honeys_card'>
                    <div className='picture_block honey_card_pict'>
                        <img src={honeys} alt="miels du rucher des albizias" className='img_def'/>
                    </div> 
                </div>
            </div>
            <div className='home_button'>
                <Link to='/products#products_container'>
                    <button className='login_btn'>
                        Je découvre les produits
                    </button>
                </Link>
            </div>
            <div>
                <div className='local_text_block'>
                    <h3 className='parisienne home_h3'>Pourquoi consommer du miel local ?</h3>
                    <p className='home_h3_text'>C'est une bonne pratique pour garantir un miel de qualité : pur et 100% naturel, non pasteurisé, et produit grâce à des méthodes traditionnelles qui respectent la nature et le cycle de vie des colonies d'abeilles.</p>
                    <p className='home_h3_text'>C'est également la possibilité de supporter l'apiculture française face à la concurrence de miels étrangers, et encourager l'agriculture notamment la production horticole, dépendante des pollinisateurs.</p>
                    <p className='home_h3_text'>C'est enfin l'occasion de découvrir toute la diversité de nos régions et déguster un miel de terroir parmis la quarantaine d'appellations recensées dans notre pays.</p>
                </div>
                <div className='collage_block'>
                    <div className='picture_block picture_block_collage'>
                        <img src={collage} alt="blason du Béarn, paysages de montagnes et rivières du Béarn" className='img_def' />
                    </div>
                </div>             
            </div>
        </div>  
    )
}

export default Home_p