// affiche les adresses en toggle. Composant de AddressComp
import { HashLink as Link } from 'react-router-hash-link';
import { useState } from 'react';
import UseFetch from './UseFetch';
import UseModal from './UseModal';
import Loader from './Loader';
import Toggle from './Toggle';
import UserInfo from './UserInfo';

function AddressToggles(props) {
    const {
        isOpen1,
        isOpen2,
        isOpen3,
        setIsOpen,
        setIsOpen1,
        setIsOpen2, 
        setIsOpen3,
    } = props;

    const [isModal, setIsModal] = useState(false);
    const [isError2, setIsError2] = useState(false);
   
    const { apiData, isFetched, isError } = UseFetch(`${process.env.REACT_APP_SERVER_URL}/api/address/getAll`);

    const deleteFetch = async(id) => {
        await fetch(`${process.env.REACT_APP_SERVER_URL}/api/address/${id}`, {
			method: 'DELETE',
            headers: {
				'Content-type': 'application/json'
			},
			credentials: 'include'
		})
        .then(res => res.json())
		.catch(() => setIsError2(true))
        window.location.reload();  // refresh pour valider les modififications
    }
    
    if(isError === true || isError2 === true)  {
		return <div className='error_div'><p className='error_message'>Une erreur est survenue sur le serveur</p></div>
	} else {
        return (
            <>
            {isFetched === false ? <Loader /> :
                apiData.length <1 && isFetched === true ? '' :
                <>
                <Toggle 
                isOpen={isOpen1} 
                setIsOpen={setIsOpen1}
                handleClick={() => {
                    isOpen1 === false ? 
                    setIsOpen1(true) : setIsOpen1(false);
                    setIsOpen(false);
                    setIsOpen2(false);
                    setIsOpen3(false);
                }} 
                title={apiData[0].title}
                content={
                    <>
                    <UserInfo 
                    civility={apiData[0].civility}
                    firstName={apiData[0].firstName} 
                    lastName={apiData[0].lastName}
                    address={apiData[0].address} 
                    additional={apiData[0].additional}
                    postCode={apiData[0].postCode} 
                    city={apiData[0].city} 
                    />
                    <button 
                    className='addressTog_delete_btn'
                    aria-label='supprimer' 
                    onClick={() => setIsModal(!isModal)}>
                        <i className="fa-solid fa-trash addressTog_icon"></i>
                    </button>  
                    <UseModal
                    isModal={isModal}
                    setIsModal={setIsModal} 
                    style_second_btn={{display: ''}}
                    option1_btn={'Ok'} 
                    option2_btn={'Annuler'} 
                    modal_text={'Supprimer cette adresse ?'}
                    handleClick={() => {
                        setIsModal(!isModal); 
                        deleteFetch(apiData[0]._id);
                    }} 
                    />
                    <Link to={`/user-account/addressMod/${apiData[0]._id}#addressMod_container`}>
                        <button className="login_btn ">
                            Modifier
                        </button>
                    </Link> 
                    </>
                }/>
                {!apiData[1] ? '' :
                    <Toggle 
                    isOpen={isOpen2} 
                    setIsOpen={setIsOpen2} 
                    handleClick={() => {
                        isOpen2 === false ? 
                        setIsOpen2(true) : setIsOpen2(false);
                        setIsOpen(false);
                        setIsOpen1(false);
                        setIsOpen3(false);
                    }} 
                    title={apiData[1].title}
                    content={
                        <>
                        <UserInfo 
                        civility={apiData[1].civility}
                        firstName={apiData[1].firstName} 
                        lastName={apiData[1].lastName}
                        address={apiData[1].address} 
                        additional={apiData[1].additional}
                        postCode={apiData[1].postCode} 
                        city={apiData[1].city} 
                        />
                        <button 
                        className='addressTog_delete_btn'
                        aria-label='supprimer' 
                        onClick={() => setIsModal(!isModal)}>
                            <i className="fa-solid fa-trash addressTog_icon"></i>
                        </button>  
                        <UseModal
                        isModal={isModal}
                        setIsModal={setIsModal} 
                        style_second_btn={{display: ''}}
                        option1_btn={'Ok'} 
                        option2_btn={'Annuler'} 
                        modal_text={'Supprimer cette adresse ?'}
                        handleClick={() => {
                            setIsModal(!isModal); 
                            deleteFetch(apiData[1]._id);
                        }} 
                        />
                        <Link to={`/user-account/addressMod/${apiData[1]._id}#addressMod_container`}>
                            <button className="login_btn ">
                                Modifier
                            </button>
                        </Link> 
                        </>
                    }/>
                }
                {!apiData[2] ? '' : 
                    <Toggle 
                    isOpen={isOpen3} 
                    setIsOpen={setIsOpen3}
                    handleClick={() => {
                        isOpen3 === false ? 
                        setIsOpen3(true) : setIsOpen3(false);
                        setIsOpen(false);
                        setIsOpen2(false);
                        setIsOpen1(false);
                    }} 
                    title={apiData[2].title}
                    content={
                        <>
                        <UserInfo 
                        civility={apiData[2].civility}
                        firstName={apiData[2].firstName} 
                        lastName={apiData[2].lastName}
                        address={apiData[2].address} 
                        additional={apiData[2].additional}
                        postCode={apiData[2].postCode} 
                        city={apiData[2].city} 
                        />
                        <button 
                        className='addressTog_delete_btn'
                        aria-label='supprimer' 
                        onClick={() => setIsModal(!isModal)}>
                            <i className="fa-solid fa-trash addressTog_icon"></i>
                        </button>  
                        <UseModal
                        isModal={isModal}
                        setIsModal={setIsModal} 
                        style_second_btn={{display: ''}}
                        option1_btn={'Ok'} 
                        option2_btn={'Annuler'} 
                        modal_text={'Supprimer cette adresse ?'}
                        handleClick={() => {
                            setIsModal(!isModal); 
                            deleteFetch(apiData[2]._id);
                        }} 
                        />
                        <Link to={`/user-account/addressMod/${apiData[2]._id}#addressMod_container`} style={{position: 'relative', zIndex: '1'}}> 
                            <button className="login_btn ">
                                Modifier
                            </button>
                        </Link>
                        </>
                    }/>
                }
                </>
            }
            </>  
        )
    }
}

export default AddressToggles