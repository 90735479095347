// slider importée sur la page Apiary
import '../style/Gallery.css';
import leftArrow from '../assets/flecheG_grande.png';
import rightArrow from '../assets/flecheD_grande.png';

function Gallery({ pictures, currentIndex, setCurrentIndex}) {
    const length = pictures.length;

    const goToNext = () => {
        const isLastSlide = currentIndex === length -1;     
        const newIndex = isLastSlide ? 0 : currentIndex +1; 
        setCurrentIndex(newIndex);
    }
    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? length -1 : currentIndex -1;
        setCurrentIndex(newIndex);
    }

    return (
        <div className='slideBlock'> 
            {length > 1 && ( // n'affiche pas les flèches de navigation s'il n'y a q'un seul élément
                <img 
                    src= {leftArrow} 
                    alt= "flèche gauche" 
                    className='leftArrow'
                    onClick= {goToPrevious} 
                />
            )}
            {length > 1 && (
                <img 
                    src= {rightArrow} 
                    alt= "flèche droite" 
                    className='rightArrow' 
                    onClick= {goToNext} 
                />
            )}
            {pictures.map((picture, index) => (
                <div key= {index} className='slider'>
                    {index === currentIndex &&
                        <img 
                            src= {picture} 
                            alt= "paysages naturels du Béarn, faune du Béarn, flore du Béarn" 
                            className='pictures img_def'
                        />
                    }
                    {index === currentIndex && length >1 && ( // n'affiche pas de numéro s'il n'y a q'un seul élément
						<span className='slider_number'>
							{currentIndex + 1}/{length}
						</span>
					)}
                </div>
            ))}
        </div>
    )
}

export default Gallery