// affiche les adresses utilisateurs
import Breadcrumb from "../components/Breadcrumb";
import AddressComp from "../components/AddressComp";

function Address() {
  return (
    <>
	<Breadcrumb 
	dest_name1='Mon compte'
	dest_link1='/user-account'
	dest_name2='Mes adresses'
	style_link2={{fontWeight: 'bold'}}
	gt2={{display: 'none'}}
	/>
	<AddressComp />
	</>
  )
}

export default Address