// affiche une fenetre modale, importé sur composants AddressToggles et pages Cart, checkout, Product, UserAccount
import '../style/UseModal.css';

function UseModal({ isModal, setIsModal, style_second_btn, option1_btn, option2_btn, modal_text, handleClick }) {
  	return (
		<div className={isModal ? 'show_modal_product' : 'hidden_modal'}>
			<div className='modal_product'>
				<p className='modal_text'>{modal_text}</p>
				<div className='separate_div'></div>
				<div>
					<button className='option_button' onClick={() => handleClick()}>{option1_btn}</button>
					<button className='option_button' style={style_second_btn} onClick={() => setIsModal(!isModal)}>{option2_btn}</button>
				</div>
			</div>
		</div>
  	)
}

export default UseModal