// affiche les commandes en cours
import '../style/History.css';
import HistoryComp from '../components/HistoryComp';
import Breadcrumb from '../components/Breadcrumb';

function History() {
    return (
        <>
        <Breadcrumb 
        dest_name1='Mon compte'
        dest_link1='/user-account'
        dest_name2='Mes commandes'
        style_link2={{fontWeight: 'bold'}}
        gt2={{display: 'none'}}
        />
        <HistoryComp />
        </>
    )
}

export default History