// ajoute un toggle sur l'élément, importé sur les composants AddressComp, AddressToggles, History_comp et page checkout
import chevron from '../assets/flecheBas.png';
import '../style/Toggle.css';

function Toggle({ isOpen, setIsOpen, content, title, id, handleClick }) {
    
    function handleOnClick() {
        setIsOpen(true);
        if(isOpen === true) setIsOpen(false)
    }

    return (
        <div className='collapse_block' id={id}>
            <button id='toggle_link_landmark' className='toggle_button' onClick={() => {handleOnClick(); handleClick();}}>
                <h2 className='collapse_h2'>{title}</h2>
                <img
                className={isOpen === true ? 'rotation' : 'chevron'}
                src={chevron}
                alt="chevron"
                />
            </button>
            <div className={`content_container ${isOpen === true ? 'open_toggle' : 'close_toggle' }`}>
                <div className='content_block'>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default Toggle