import { HashLink as Link } from 'react-router-hash-link';

function Error() {
    return (
        <div className='error_block g_layout'>
            <h1 style={{fontSize: '90px', marginBottom: '10px'}}>404</h1>
            <h2 style={{fontWeight: 'bold', fontSize: '20px', marginBottom: '40px'}}>Désolé, la page que vous recherchez n'existe pas.</h2>
            <div style={{textAlign: 'center'}}>
                <Link className='link_effect link_underline' style={{fontSize: '18px', width: 'max-content'}} to="/#home_container">
                    Retourner sur la page d’accueil
                </Link>
            </div>
        </div>
    )    
}

export default Error