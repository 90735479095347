import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ReactGA from "react-ga4";

import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Registration from './pages/Registration';
import Address from './pages/Address';
import AddressReg from './pages/AddressReg';
import AddressMod from './pages/AddressMod';
import UserAccount from './pages/UserAccount';
import ForgotPassword from './pages/ForgotPassword';
import Reset from './pages/Reset';
import History from './pages/History';
import Personnal from './pages/Personnal';
import Bill from './pages/Bill';
import Modify from './pages/Modify';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Products from './pages/Products';
import Article from './pages/Article';
import About from './pages/About';
import Info from './pages/Info';
import Error from './pages/Error';
import Header from './components/Header';
import Footer from './components/Footer';
import Success from './pages/Success';
import Cancel from './pages/Cancel';

function App() {
    const [isAuth, setIsAuth] = useState(false);
    ReactGA.initialize("GTM-PTD6DXBK")

    useEffect(() => {
        async function autoLogin() {
            await fetch(`${process.env.REACT_APP_SERVER_URL}/api/auth/login`, {
                method: "GET",
                credentials: "include",
            })
            .then((res) => {
                if(res.ok) setIsAuth(true);
            })
            .catch((err) => console.log(err.message))
        }
        autoLogin();
    }, []);

    function PrivateRoute({ children }) {
        if(isAuth === true) {
            return <>{children}</>
        } else {
           return;  //<Routes><Route path="/" element={<Home />} /></Routes>
        }
    }

    const savedCart = localStorage.getItem('cart');
    const [cart, updateCart] = useState(savedCart ? JSON.parse(savedCart) : []);

    const savedDelivery = localStorage.getItem('delivery');
    const [delivery, updateDelivery] = useState(savedDelivery ? JSON.parse(savedDelivery) : []);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
        localStorage.setItem('delivery', JSON.stringify(delivery));
      }, [cart, delivery]);

    return (
        <>
        <Header cart={cart} isAuth={isAuth} />
        <main>
            <Routes>
                <Route path="/" element={
                    <Home />
                } />
                <Route path="/apiary" element={
                    <About />
                } />
                <Route path="/info" element={
                    <Info />
                } />
                <Route path="*" element={
                    <Error />
                } />
                <Route path="/signup" element={
                    <Signup isAuth={isAuth} setIsAuth={setIsAuth} />
                } />
                <Route path="/login" element={
                    <Login isAuth={isAuth} setIsAuth={setIsAuth} />
                } />
                <Route path="/forgotPassword" element={
                    <ForgotPassword />
                } />
                <Route path="/reset/:resetToken" element={
                    <Reset />
                } />
                <Route exact path="/user-account" element={<PrivateRoute>
                    <UserAccount /></PrivateRoute>
                } />
                <Route path="/user-account/personnal" element={<PrivateRoute>
                    <Personnal  /></PrivateRoute>
                } />
                <Route path="/user-account/history" element={<PrivateRoute>
                    <History /></PrivateRoute>
                } />
                <Route path="/user-account/bill" element={<PrivateRoute>
                    <Bill /></PrivateRoute>
                } />
                <Route path="/user-account/modify/:id" element={<PrivateRoute>
                    <Modify isAuth={isAuth} setIsAuth={setIsAuth}/></PrivateRoute>
                } />
                <Route path="/user-account/registration" element={<PrivateRoute>
                    <Registration isAuth={isAuth} setIsAuth={setIsAuth}/></PrivateRoute>
                } />
                <Route path="/user-account/address" element={<PrivateRoute>
                    <Address /></PrivateRoute>
                } />
                <Route path="/user-account/registration/addressReg" element={<PrivateRoute>
                    <AddressReg /></PrivateRoute>
                } />
                <Route path="/user-account/addressMod/:id" element={<PrivateRoute>
                    <AddressMod /></PrivateRoute>
                } />
                <Route exact path="/products" element={
                    <Products cart={cart} updateCart={updateCart} />
                } />
                <Route path="/products/article/:id" element={
                    <Article cart={cart} updateCart={updateCart} />
                } />
                <Route exact path="/cart" element={
                    <Cart isAuth={isAuth} cart={cart} updateCart={updateCart} />
                } />
                <Route path="/cart/checkout" element={<PrivateRoute>
                    <Checkout 
                    cart={cart} 
                    delivery={delivery} 
                    updateDelivery={updateDelivery} 
                    />
                </PrivateRoute>} />
                <Route path="/cart/checkout/success" element={<PrivateRoute>
                    <Success delivery={delivery} cart={cart} /></PrivateRoute>
                } />
                <Route path="/cart/checkout/cancel" element={<PrivateRoute>
                    <Cancel /></PrivateRoute>
                } />
            </Routes>
        </main>
        <Footer />
        </>  
    );
}

export default App;

