// menu burger et icones-lien sur mobile, liens classiques sur les autres versions
import { Link } from 'react-router-dom';
import '../style/Header.css';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Logo from '../assets/logo5.svg';

function Header(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isClicked2, setIsClicked2] =useState(false);
    const [isClicked3, setIsClicked3] =useState(false);
    const [isClicked4, setIsClicked4] =useState(false);
    const [isClicked5, setIsClicked5] =useState(false);
    
    const location = useLocation(); 

    useEffect(() => {
        if(location.pathname === '/') {
        setIsClicked(true);
        setIsClicked2(false);
        setIsClicked3(false);
        setIsClicked4(false);
        setIsClicked5(false);
        }
    }, [location.pathname])
    
    const quantities = props.cart.length === 0 ? 0 : props.cart.map((article => article.quantity)).reduce((a, b) => a+b);

    return (
        location.pathname === '/cart/checkout/success' ? <div></div> :
        <nav className={`navbar ${isOpen ? "show-nav" : "hide-nav"}`}>
            <Link to='/' className='logo_link'>
                <div className='logo_container'>
                    <img src={Logo} alt="logo entreprise Le Rucher des Albizias" className='img_def' style={{color : 'white'}}/>
                </div>
                
            </Link>
            <ul className="navbar-links">
                <li className={isClicked ? "navbar_items_clicked" : "navbar-items"} >
                    <Link 
                    to='/' 
                    className="navbar-link" 
                    onClick={() => {
                        setIsOpen(!isOpen); 
                        setIsClicked(true);
                        setIsClicked2(false);
                        setIsClicked3(false);
                        setIsClicked4(false);
                        setIsClicked5(false);
                    }}
                    >Accueil</Link>
                </li>
                <li className={isClicked2 && window.location.href.includes('products') ? "navbar_items_clicked" : "navbar-items"}>
                    <Link 
                    to='/products' 
                    className="navbar-link" 
                    onClick={() => {
                        setIsOpen(!isOpen);
                        setIsClicked(false);
                        setIsClicked2(true);
                        setIsClicked3(false);
                        setIsClicked4(false);
                        setIsClicked5(false);
                    }}
                    >Miels</Link>
                </li>
                <li className={isClicked3 ? "navbar_items_clicked" : "navbar-items"}>
                    <Link 
                    to={props.isAuth === true ? '/user-account' : '/login'} 
                    className="navbar-link" 
                    onClick={() => {
                        setIsOpen(!isOpen);
                        setIsClicked(false);
                        setIsClicked2(false);
                        setIsClicked3(true);
                        setIsClicked4(false);
                        setIsClicked5(false);
                    }}
                    >{props.isAuth === true ? 'Compte' : 'Connexion'}</Link>
                </li>
                <li className={isClicked4 || location.pathname === '/cart' ? "navbar_items_clicked" : "navbar-items"}>
                    <Link 
                    to='/cart' 
                    className="navbar-link" 
                    onClick={() => {
                        setIsOpen(!isOpen);
                        setIsClicked(false);
                        setIsClicked2(false);
                        setIsClicked3(false);
                        setIsClicked4(true);
                        setIsClicked5(false);
                    }}
                    >Panier</Link>
                </li>
                <li className={isClicked5 ? "navbar_items_clicked" : "navbar-items"}>
                    <Link 
                    to='/apiary' 
                    className="navbar-link" 
                    onClick={() => {
                        setIsOpen(!isOpen);
                        setIsClicked(false);
                        setIsClicked2(false);
                        setIsClicked3(false);
                        setIsClicked4(false);
                        setIsClicked5(true);
                    }}
                    >Notre rucher</Link>
                </li>
            </ul>
            <div className='icon_cart_block'>
                <Link aria-label='lien compte utilisateur' to={props.isAuth === true ? '/user-account' : '/login'}>
                    <div>
                        <i className="fa-solid fa-user icon_header"></i>
                    </div>
                </Link>
                <Link aria-label='lien panier' to='/cart'>
                    <i className="fa-solid fa-basket-shopping icon_header"></i>
                </Link>
                <div className='icon_quantity'>{quantities}</div>
            </div>
            <div>
                <button className="navbar-burger" aria-label='menu burger' onClick={() => setIsOpen(!isOpen)}>
                    <span className="navbar-bar"></span>
                </button> 
            </div>
            
        </nav> 
    )   
}

export default Header