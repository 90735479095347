// formulaire importé sur les pages AddressMod, AddressReg, ForgotPassword, Login, Modify, Registration, Reset, Signup
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import FormInput from "./FormInput";
import FormPassword from "./FormPassword";
import FormSelect from "./FormSelect";
import Loader from "./Loader";
import '../style/Form.css';

function Form(props) {
	const { 
		id, 
		textButton,
		url,
		method,
		navUrl,
		errorContent,
		successContent,
		// active/désactive un champ ou un élément d'affichage (display: none)
		styleTitle,
		styleCivility,
		styleLastName,
		styleFirstName,
		styleEmail,
		stylePassword,
		styleOldPassword,
		styleConfirm, // confirmation mot de passe
		styleList,	// liste des éléments nécessaires au mot de passe 
		styleOldList,
		styleAddress,
		styleAdditional, // info complémentaires sur l'adresse
		stylePostCode,
		styleCity,
		stylePhone,
		styleButton,
		// active/désactive l'obligation de remplir un champs (required: true)
		requiredCivility,
		requiredLastName,
		requiredFirstName,
		requiredEmail,
		requiredPassword,
		requiredOldPassword,
		requiredConfirm,
		requiredAddress,
		requiredPostCode,
		requiredCity,
		requiredPhone,
		requiredTitle,
		// active/désactive des placeHolders
		placeholderAddress,
		placeholderAdditional,
		placeholderTitle,

	} = props
	
    const navigate = useNavigate();
	const location = useLocation();
    const [apiData, setApiData] = useState();
    const [isError, setIsError] = useState(false);
	const [loader, setLoader] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const [myForm, setMyForm] = useState({
        formValues: {
            civility: '',
            lastName: '',
			firstName: '',
			email: '',
			password: '',
			oldPassword: '',
			confirm: '',
			address: '',
			additional: '',
			postCode: '',
			city: '',
			phone: '',
			title: ''
        },
        formErrors: {
            lastName: '',
			firstName: '',
			email: '',
			password: '',
			oldPassword: '',
			confirm: '',
			address: '',
			additional: '',
			postCode: '',
			city: '',
			phone: '',
			title: ''
        },
        formValidity: {
			civility: true,
            lastName: true,
			firstName: true,
			email: true,
			password: true,
			oldPassword: true,
			confirm: true,
			address: true,
			additional: true,
			postCode: true,
			city: true,
			phone: true,
			title: true
        }
    });

	const handleChange = ({ target }) => {  // récupére la valeur du champ ciblé, l’enregistre, la met à jour, et 
        const { formValues } = myForm;      // valide la valeur renseignée en appelant la fonction handleValidation.
        formValues[target.name] = target.value;
        setMyForm({ formValues });
        handleValidation(target);
    }

    const handleValidation = (target) => {     // cible le champ qui doit validé, valide en fonction des regex,
        const { name, value } = target;			// affiche les erreurs, note comme valides les champs validés
		        
        const fieldErrors = myForm.formErrors;
        const validity = myForm.formValidity;
		const passwordValue = myForm.formValues.password
		const confirmValue = myForm.formValues.confirm
		
		const isCivility = name === 'civility';
		const isLastName = name === 'lastName';
		const isFirstName = name === 'firstName';
        const isEmail = name === 'email';
		const isPassword = name === 'password';
		const isOldPassword = name === 'password';
		const isConfirm = name === 'confirm';
		const isAddress = name === 'address';
		const isAdditional = name === 'additional';
		const isPostCode = name === 'postCode';
		const isCity = name === 'city';
		const isPhone = name === 'phone';
		const isTitle = name === 'title';

		const lastNameTest = /^[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ \-']{2,45}$/i;
		const firstNameTest = /^[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ \-']{2,45}$/i;
		const emailTest = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
		const passwordTest = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i;
		const oldPasswordTest = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i;
		const addressTest = /^[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ0-9 \-']{1,45}$/i;
		const additionalTest = /^[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ0-9 \-']{1,45}$/i;
		const postCodeTest = /^(?:0[1-9]|[1-8]\d|9[0-5])\d{3}$/i;
		const cityTest = /^[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ0-9 \-']{1,45}$/i;
		const phoneTest = /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/i;
		const titleTest = /^[a-z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ \-']{1,20}$/i;

		if (isCivility) {
			validity[name] = value.length > 0;
			fieldErrors[name] = validity[name] ? '' : `Ce champ n'est pas valide`;
		}
		if (isLastName) {
			validity[name] = lastNameTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Ce nom n'est pas valide`; 
		}
		if (isFirstName) {
			validity[name] = firstNameTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Ce prénom n'est pas valide`;
		}
		if (isEmail) {
			validity[name] = emailTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Cet email n'est pas valide`;
		}
		if (isPassword) { 
			validity[name] = passwordTest.test(value); 
			fieldErrors[name] = validity[name] ? '' : `Ce mot de passe n'est pas valide`;
		}
		if (isOldPassword) { 
			validity[name] = oldPasswordTest.test(value); 
			fieldErrors[name] = validity[name] ? '' : `Ce mot de passe n'est pas valide`;
		}
		if (isConfirm) { 
			validity[name] = confirmValue === passwordValue; 
			fieldErrors[name] = validity[name] ? '' : `Mots de passe non identiques`;
		}
		if (isAddress) {
			validity[name] = addressTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Cette adresse n'est pas valide`;
		}
		if (isAdditional) {
			validity[name] = additionalTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Cette adresse n'est pas valide`;
		}
		if (isPostCode) {
			validity[name] = postCodeTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Ce code postal n'est pas valide`;
		}
		if (isCity) {
			validity[name] = cityTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Cette ville n'est pas valide`;
		}
		if (isPhone) {
			validity[name] = phoneTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Ce numéro n'est pas valide`;
		}
		if (isTitle) {
			validity[name] = titleTest.test(value);
			fieldErrors[name] = validity[name] ? '' : `Ce titre n'est pas valide`;
		}
        
        setMyForm({
            ...myForm,
            formErrors: fieldErrors,
            formValidity: validity,
        });
    }

    async function handleSubmit(event) {    // si tous les champs sont valides, soumet le formulaire,
        event.preventDefault();       // sinon renvoie la fonction handleValidation.
        const { formValues, formValidity } = myForm;
        if (Object.values(formValidity).every(Boolean)) {	// = formulaire valide
			setLoader(true);
			await fetch(url, {
				method: (method),
				body: JSON.stringify(formValues),
				headers : {
					'Content-type': 'application/json'
				},
				credentials: 'include'
			})
			.then(res => {
				if(res.ok) {
					setLoader(false);
					setIsError(false);
					setIsSuccess(true);
					return res.json();
				} if(res.ok && props.setIsAuth !== undefined) {
					setLoader(false);
					setIsError(false);
					setIsSuccess(true);
					props.setIsAuth(true);
					return res.json();
				} else {
					setLoader(false);
					setIsError(true);
					return res.json();
				}
			})
			.then(data => setApiData(data))
			.catch(err => console.log(err))
		}
	}

	useEffect(() => {
		if(isSuccess === true && apiData) navigate(navUrl);
		if(isSuccess === true && apiData && props.setIsAuth) window.location.assign(navUrl); // refresh de la page + redirection 
		
	}, [apiData, isSuccess, navUrl, navigate, props.setIsAuth])

	return loader === true ? <Loader /> : (
        <div className='profil_block'>
			<div className="form_block" style={location.pathname === '/cart/checkout' ? {width: '100%'} : {}}>
				<form id={id} className="form_body" onSubmit={handleSubmit} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault();}}>
					<FormInput
					style={styleTitle}
					required={requiredTitle}
					text="Donner un titre à cette adresse"
					handleChange={handleChange} 
					className={myForm.formErrors.title === '' && myForm.formValues.title !== '' ? 'form_input valid' : "form_input"}
					type='text'
					name='title'
					value={myForm.formValues.title}
					invalid={myForm.formErrors.title}
					placeholder={placeholderTitle}
					/>
					<FormSelect 
					style={styleCivility}
					required={requiredCivility}
					text='Civilité'
					handleChange={handleChange} 
					className={myForm.formErrors.civility === '' && myForm.formValues.civility !== '' ? 'form_input valid' : "form_input"}
					type='text'
					name='civility'
					value1='mister'
					value2='miss'
					invalid={myForm.formErrors.civility}
					/>
					<FormInput
					style={styleLastName}
					required={requiredLastName} 
					text='Nom'
					handleChange={handleChange} 
					className={myForm.formErrors.lastName === '' && myForm.formValues.lastName !== '' ? 'form_input valid' : "form_input"}
					type='text'
					name='lastName'
					value={myForm.formValues.lastName}
					invalid={myForm.formErrors.lastName}
					/>
					<FormInput 
					style={styleFirstName}
					required={requiredFirstName}
					text='Prénom'
					handleChange={handleChange} 
					className={myForm.formErrors.firstName === '' && myForm.formValues.firstName !== '' ? 'form_input valid' : "form_input"}
					type='text'
					name='firstName'
					value={myForm.formValues.firstName}
					invalid={myForm.formErrors.firstName}
					/>
					<FormInput 
					style={styleEmail}
					required={requiredEmail}
					text='Email'
					handleChange={handleChange} 
					className={myForm.formErrors.email === '' && myForm.formValues.email !== '' ? 'form_input valid' : "form_input"}
					type='email'
					name='email'
					value={myForm.formValues.email}
					invalid={myForm.formErrors.email}
					/>
					<FormPassword 
					style={styleOldPassword}
					styleList={styleOldList}
					required={requiredOldPassword}
					text='Mot de passe actuel'
					handleChange={handleChange} 
					className={myForm.formErrors.oldPassword === '' && myForm.formValues.oldPassword !== '' ? 'form_input valid' : "form_input"}
					name='oldPassword'
					value={myForm.formValues.oldPassword}
					invalid={myForm.formErrors.oldPassword}
					disabled={myForm.formErrors.oldPassword === '' && myForm.formValues.oldPassword !== ''}
					/>
					<FormPassword 
					style={stylePassword}
					styleList={styleList}
					required={requiredPassword}
					text={location.pathname === '/user-account/new-password' ? 'Nouveau mot de passe' : 'Mot de passe'}
					handleChange={handleChange} 
					className={myForm.formErrors.password === '' && myForm.formValues.password !== '' ? 'form_input valid' : "form_input"}
					name='password'
					value={myForm.formValues.password}
					invalid={myForm.formErrors.password}
					disabled={myForm.formErrors.password === '' && myForm.formValues.password !== ''}
					/>
					<FormPassword 
					style={styleConfirm}
					styleList={{display: 'none'}}
					required={requiredConfirm}
					text='Confirmez le mot de passe'
					handleChange={handleChange} 
					className={myForm.formErrors.confirm === '' && myForm.formValues.confirm !== '' ? 'form_input valid' : "form_input"}
					name='confirm'
					value={myForm.formValues.confirm}
					invalid={myForm.formErrors.confirm}
					disabled={myForm.formErrors.confirm === '' && myForm.formValues.confirm !== ''}
					/>
					<FormInput
					style={styleAddress}
					required={requiredAddress} 
					text={'Adresse'}
					handleChange={handleChange} 
					className={myForm.formErrors.address === '' && myForm.formValues.address !== '' ? 'form_input valid' : "form_input"}
					type='text'
					name='address'
					value={myForm.formValues.address}
					invalid={myForm.formErrors.address}
					placeholder={placeholderAddress}

					/>
					<FormInput
					style={styleAdditional} 
					text="Complément d'adresse"
					handleChange={handleChange} 
					className={myForm.formErrors.additional === '' && myForm.formValues.additional !== '' ? 'form_input valid' : "form_input"}
					type='text'
					name='additional'
					value={myForm.formValues.additional}
					invalid={myForm.formErrors.additional}
					placeholder={placeholderAdditional}
					/>
					<FormInput
					style={stylePostCode}
					required={requiredPostCode}   
					text="Code postal"
					handleChange={handleChange} 
					className={myForm.formErrors.postCode === '' && myForm.formValues.postCode !== '' ? 'form_input valid' : "form_input"}
					type='text'
					name='postCode'
					value={myForm.formValues.postCode}
					invalid={myForm.formErrors.postCode}
					/>
					<FormInput
					style={styleCity}
					required={requiredCity}   
					text="Ville"
					handleChange={handleChange} 
					className={myForm.formErrors.city === '' && myForm.formValues.city !== '' ? 'form_input valid' : "form_input"}
					type='text'
					name='city'
					value={myForm.formValues.city}
					invalid={myForm.formErrors.city}
					/>
					<FormInput
					style={stylePhone}
					required={requiredPhone}
					text="N° de téléphone"
					handleChange={handleChange} 
					className={myForm.formErrors.phone === '' && myForm.formValues.phone !== '' ? 'form_input valid' : "form_input"}
					type='phone'
					name='phone'
					value={myForm.formValues.phone}
					invalid={myForm.formErrors.phone}
					/>
					<div>
						{isError === true ? <p style={{fontSize: '14px', color : 'rgb(208, 24, 24)'}}>{errorContent}</p> : 
						isSuccess === true ? <p style={{fontSize: '14px'}}>{successContent}</p> : null
						}
					</div>
					<button type="submit" className="login_btn" style={styleButton}>
                		{textButton}
            		</button>
				</form>
			</div>    
        </div>
  	)
}

export default Form