// affiche les dossiers des données utilisateur sur mobile, affiche directement ces données sur tablette et desktop
import { HashLink as Link } from 'react-router-hash-link';
import { useState } from 'react';
import '../style/UserAccount.css';
import Breadcrumb from '../components/Breadcrumb';
import Loader from '../components/Loader';
import HistoryComp from '../components/HistoryComp';
import PersonnalComp from '../components/PersonnalComp';
import BillComp from '../components/BillComp';
import AddressComp from '../components/AddressComp';
import UseModal from '../components/UseModal';

function UserAccount() {
    const [isPersonnalOpen, setIsPersonnalOpen] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [isAddressOpen, setIsAddressOpen] = useState(true);
    const [isBillOpen, setIsBillOpen] = useState(false);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isModal, setIsModal] = useState(false);
    
    const logoutFetch = async() => {
        setIsLoader(true);
        await fetch(`${process.env.REACT_APP_SERVER_URL}/api/auth/logout`, {
            method: 'GET',
            headers : {
                'Content-type': 'application/json'
            },
            credentials: 'include'
        })
        .then(res => {
            if(res.ok) {
                setIsLoader(false);
                return res.json();
            }else {
                setIsLoader(false);
                return res.json();
            }
        })
        .catch(() => setIsError(true));
        window.location.assign('/');
	}

    return ( 
        <>
        <Breadcrumb 
        dest_name1='Mon compte'
        style_link1={{fontWeight: 'bold'}}
        gt1={{display: 'none'}}
        gt2={{display: 'none'}}
        />
        <div id='userAccount_container' className='g_layout'>
            {isError === true ? <div className='error_div'><p className='error_message'>Une erreur s'est produite sur le serveur</p></div> : 
            isLoader === true ? <Loader /> :
                <>
                <h1 className='userAccount_h1 h1_desk_fontSize'>Mon compte</h1>
                <div className='userAccount_display'>
                    <div className='userAccount_block '>
                        <Link 
                        className='userAccount_link' 
                        to={window.innerWidth > 991 ? '' : '/user-account/personnal#personnal_container'}
                        onClick={() => {
                            setIsPersonnalOpen(true);
                            setIsAddressOpen(false);
                            setIsHistoryOpen(false);
                            setIsBillOpen(false);
                        }}>
                            <div className='userAccount_card'>
                                <i className="fa-solid fa-user icon_account"></i>
                                <h2 className='userAccount_h2 h_desk_fontSize'>Mes coordonnées</h2>
                            </div>
                        </Link>
                        <div className='separate_block'>
                            <div className='separate sep_user'></div>
                        </div>
                    </div>
                    <div className='userAccount_block '>
                        <Link 
                        className='userAccount_link' 
                        to={window.innerWidth > 991 ? '' : '/user-account/address#addressComp_container'}
                        onClick={() => {
                            setIsPersonnalOpen(false);
                            setIsAddressOpen(true);
                            setIsHistoryOpen(false);
                            setIsBillOpen(false);
                        }}>
                            <div className='userAccount_card'>
                                <i className="fa-solid fa-address-book icon_account"></i>
                                <h2 className='userAccount_h2 h_desk_fontSize'>Mes adresses</h2>
                            </div>
                        </Link>
                        <div className='separate_block'>
                            <div className='separate sep_user'></div>
                        </div>
                    </div>
                    <div className='userAccount_block '>
                        <Link 
                        className='userAccount_link' 
                        to={window.innerWidth > 991 ? '' : '/user-account/history#history_container'}
                        onClick={() => {
                            setIsPersonnalOpen(false);
                            setIsAddressOpen(false);
                            setIsHistoryOpen(true);
                            setIsBillOpen(false);
                        }}>
                            <div className='userAccount_card'>
                                <i className="fa-solid fa-calendar icon_account"></i>
                                <h2 className='userAccount_h2 h_desk_fontSize'>Suivi des commandes</h2>
                            </div>
                        </Link>
                        <div className='separate_block'>
                            <div className='separate sep_user'></div>
                        </div>
                    </div>
                    <div className='userAccount_block '>
                        <Link 
                        className='userAccount_link' 
                        to={window.innerWidth > 991 ? '' : '/user-account/bill#bill_container'}
                        onClick={() => {
                            setIsPersonnalOpen(false);
                            setIsAddressOpen(false);
                            setIsHistoryOpen(false);
                            setIsBillOpen(true);
                        }}>
                            <div className='userAccount_card'>
                                <i className="fa-solid fa-calendar icon_account"></i>
                                <h2 className='userAccount_h2 h_desk_fontSize'>Mes factures</h2>
                            </div>
                        </Link>
                        <div className='separate_block'>
                            <div className='separate sep_user'></div>
                        </div>
                    </div>
                    <div className='userAccount_block '>
                        <div className='userAccount_card'>
                            <button className='logout_button' onClick={() => setIsModal(!isModal)} aria-label='se déconnecter'>
                                <i className="fa-solid fa-arrow-right-from-bracket icon_account"></i>
                                <h2 className='userAccount_h2 h_desk_fontSize'>Se déconnecter</h2>
                            </button>    
                        </div>
                        <UseModal
                        isModal={isModal}
                        setIsModal={setIsModal} 
                        style_second_btn={{display: ''}}
                        option1_btn={'Ok'} 
                        option2_btn={'Annuler'} 
                        modal_text={'Se déconnecter ?'}
                        handleClick={() => {
                            setIsModal(!isModal); 
                            logoutFetch();
                        }} 
                        /> 
                        <div className='separate_block'>
                            <div className='separate sep_user'></div>
                        </div>
                    </div>  
                </div>
                <div className='userAccount_desk_display' style={window.innerWidth > 991 ? {display: 'block'} : {display: 'none'}} >
                    {isPersonnalOpen === true ? <PersonnalComp /> : 
                        isHistoryOpen === true  ? <HistoryComp /> :
                        isBillOpen === true  ? <BillComp /> : <AddressComp />
                    }
                </div>
                </>
            }   
        </div>
        </>
    )
}

export default UserAccount;