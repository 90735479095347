// affiche les Conditions Générales de vente, plan du site + mentions légales
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumb from '../components/Breadcrumb';
import '../style/Info.css';

function Info() {
    return (
        <>
        <Breadcrumb 
        dest_name1='CGV'
        style_link1={{fontWeight: 'bold'}}
        gt1={{display: 'none'}}
        gt2={{display: 'none'}}
        />
        <div id='cgv_block' className='info_container g_layout'>
            <div>
                <h1 className='h1_info'>Conditions générales de vente</h1>
                <p>Les présentes conditions générales de vente sont conclues d’une part, avec la société Le Rucher des Albizias, entreprise immatriculée au Registre du commerce et des sociétés sous le numéro 978 960 797 R.C.S Pau, dont le siège social est situé au 15 rue du Pic du Ger - 64230 DENGUIN, ci-après dénommée, « le Vendeur », et, d’autre part, toute personne physique ou morale souhaitant procéder à un achat via le site internet rucherdesalbizias.fr, ci-après dénommé « le Client ».</p><br />
                <span>1.Objet</span>
                <p>Les présentes Conditions Générales de vente visent à définir les relations contractuelles entre le Vendeur et le Client, et les conditions applicables à tout achat effectué par le biais du site internet rucherdesalbizias.fr. L’acquisition d’un bien à travers le présent site implique une acceptation sans réserve par le Client des présentes conditions de vente. Les produits proposés sur le site internet rucherdesalbizias.fr sont destinés exclusivement aux particuliers et non aux revendeurs ou autres intermédiaires. Ces conditions de vente prévaudront sur toutes autres conditions générales ou particulières non expressément agréées par le Vendeur. Le Vendeur se réserve le droit de modifier ses conditions de vente à tout moment. Dans ce cas, les conditions applicables seront celles en vigueur à la date de la commande effectuée par le Client.</p><br />
                <span>2. Caractéristiques des produits</span>
                <p>Les produits sont ceux qui figurent sur le site internet rucherdesalbizias.fr. Les produits sont offerts à la vente dans la limite des stocks disponibles tels que précisés lors de la passation de commande. En cas d’indisponibilité exceptionnelle d’un produit après la passation de commande, le Client sera informé par courrier électronique ou par téléphone dans un délai de 48 h (jours ouvrés). Le Client pourra alors demander l’annulation de la commande et son remboursement ou l’échange du ou des produits manquants. Conformément à l'article L. 111-1 du Code de la consommation, le Client peut, préalablement à sa commande, prendre connaissance, sur le site Internet du Vendeur, des caractéristiques essentielles du ou des produit(s) qu'il désire commander. Les photographies sur le site internet, non contractuelles, sont les plus fidèles possibles mais ne peuvent assurer une similitude parfaite avec le produit offert.</p><br />
                <span>3. Commandes</span>
                <p>Le Client qui souhaite acheter un produit sur le site rucherdesalbizias.fr doit obligatoirement :</p>
                <ul style={{padding: '0px'}}>
                    <li>-indiquer toutes les coordonnées demandées,</li>
                    <li>-remplir le bon de commande en ligne donnant toutes les références du produit choisi,</li>
                    <li>-valider sa commande après l’avoir vérifié,</li>
                    <li>-effectuer le paiement dans les conditions prévues,</li>
                    <li>-confirmer sa commande et son règlement.</li>
                </ul>
                <p>La confirmation de la commande entraîne acceptation des présentes Conditions Générales de vente, la reconnaissance d’en avoir parfaitement connaissance et la renonciation à se prévaloir de toute autre condition. La vente n’est définitive qu’après l’envoi au client de la confirmation de commande par le Vendeur par courrier électronique, envoyée après encaissement du prix.</p><br />
                <span>4. Prix</span>
                <p>Les prix des produits vendus sur le site internet rucherdesalbizias.fr sont indiquées en euros TTC (toutes taxes comprises). Le Vendeur se réserve la possibilité de modifier ses prix à tout moment, étant toutefois entendu que le prix figurant sur le site internet au jour de la validation de la commande sera le seul applicable au Client. Les prix indiqués ne comprennent pas les frais de traitement de commandes, de transport, et de livraison qui seront facturés en supplément et indiqué au Client lors de sa commande.</p><br />
                <span>5. Modalités de paiement</span>
                <p>Le prix est exigible à la commande. Les paiements seront effectués uniquement par carte bancaire. Le paiement par carte bancaire est irrévocable, sauf en cas d'utilisation frauduleuse de la carte. Dans ce cas, le Client peut demander l'annulation du paiement et la restitution des sommes correspondantes. Les données de paiement sont échangées par le biais d’un système sécurisé de telle sorte que les informations transmises sont cryptées par un logiciel et qu’aucun tiers ne peut en prendre connaissance au cours du transport sur le réseau. Une facture au format PDF sera disponible sur le site.</p><br />
                <span>6. Livraison</span>
                <p>Les produits proposés à la vente sur le site internet sont uniquement vendus et livrés en France Métropolitaine, Outre-mer et Corse dans un délai de 15 jours ouvrés à compter de la confirmation de commande.</p>
                <p>Des frais de port ainsi que des délais de livraison différents s’appliqueront selon la zone géographique. Les livraisons sont faites à l’adresse indiquée dans le bon de commande. </p>
                <p>Le Client peut également choisir une livraison en point relais.</p>
                <p>Si les Produits commandés n'ont pas été livrés dans un délai de 30 jours après la date indicative de livraison, pour toute autre cause que la force majeure ou le fait du Client, notamment en cas de grève des services postaux, de transporteurs, et de catastrophes causées par des inondations ou incendies, impactant les moyens de communication; la vente pourra être résolue à la demande écrite du Client dans les conditions prévues aux articles L. 216-2, L. 216-3, L. 241-4 du Code de la consommation. Les sommes versées par le Client lui seront alors restituées au plus tard dans les quatorze jours qui suivent la date de dénonciation du contrat, à l'exclusion de toute indemnisation ou retenue. En cas de non-conformité du Produit livré, le Vendeur - s'engage à y remédier ou à rembourser le Client, comme indiqué à l'article - Responsabilité du Vendeur - Garantie. </p>
                <p>Le Client est tenu de vérifier l'état des produits livrés. Il dispose d'un délai de 14 jours à compter de la livraison pour formuler par écrit toutes réserves ou réclamations pour non-conformité ou vice apparent des Produits livrés avec tous les justificatifs y afférents (photos notamment). Les frais de retour seront à la charge du client. Passé ce délai et à défaut d'avoir respecté ces formalités, les Produits seront réputés conformes et exempts de tout vice apparent et aucune réclamation ne pourra être valablement acceptée par le Vendeur. Le Vendeur remboursera ou remplacera dans les plus brefs délais et à ses frais, les Produits livrés dont les défauts de conformité ou les vices apparents ou cachés auront été dûment prouvés par le Client, dans les conditions prévues aux articles L. 217-4 et suivants du Code de la consommation et celles prévues aux présentes Conditions Générales de Vente (voir garanties, notamment).</p><br/>
                <span>7. Transfert de propriété – Transfert des risques</span>
                <p>Le transfert de propriété des Produits du Vendeur, au profit du Client, ne sera réalisé qu'après complet paiement du prix par ce dernier, et ce quelle que soit la date de livraison desdits Produits. </p>
                <p>Quelle que soit la date du transfert de propriété des Produits, le transfert des risques de perte et de détérioration s'y rapportant, ne sera réalisé qu'au moment où le Client prendra physiquement possession des Produits. Les Produits voyagent donc aux risques et périls du Vendeur.</p><br />
                <span>8. Garantie – Échange et remboursement</span>
                <p>Les Produits vendus sur le site Internet rucherdesalbizias.fr sont conformes à la réglementation en vigueur en France. Les Produits fournis par le Vendeur bénéficient de plein droit et sans paiement complémentaire, indépendamment du droit de rétractation, conformément aux dispositions légales :</p>
                <ul style={{padding: '0px'}}>
                    <li>-de la garantie légale de conformité, pour les Produits apparemment défectueux, abîmés ou endommagés ou ne correspondant pas à la commande,</li>
                    <li>-de la garantie légale contre les vices cachés provenant d'un défaut de matière, de conception ou de fabrication affectant les produits livrés et les rendant impropres à l'utilisation.</li>
                </ul>
                <p>La garantie légale de conformité s'applique indépendamment de la garantie commerciale pouvant éventuellement couvrir le Produit.</p>
                <p>Le Client peut décider de mettre en œuvre la garantie contre les défauts cachés; dans ce cas, il peut choisir entre la résolution de la vente ou l'échange du produit. Afin de faire valoir ses droits, le Client devra informer le Vendeur, par écrit, de la non-conformité des Produits dans un délai maximum de 7 jours à compter de la livraison des Produits ou de la découverte des vices cachés dans les délais ci-dessus visés et retourner ou rapporter en magasin les Produits défectueux dans l'état dans lequel ils ont été reçus.</p>
                <p>La garantie du Vendeur est, en tout état de cause, limitée au remplacement ou au remboursement des Produits non conformes ou affectés d'un vice.</p>
                <p>Les produits alimentaires, conformément à la législation, comportent une date de durabilité minimale qui doit être respectée par le Client. </p>
                <p style={{fontWeight: 'bold'}}>Le Vendeur rappelle en sus au Client que le miel est un produit sensible qui évolue au fil du temps. Le miel peut ainsi naturellement se figer, cristalliser, ou présenter à la surface une pellicule blanche ou de la cire. Ces phénomènes sont totalement naturels et ne remettent pas en cause la qualité du produit qui ne peut, dès lors, être considéré comme défectueux sur ce seul fondement.</p><br />
                <span>9. Responsabilité</span>
                <p>Le Vendeur, dans le processus de vente en ligne, n’est tenu que par une obligation de moyen. Sa responsabilité ne pourra être engagée pour un dommage résultant de l’utilisation du réseau internet, tel que la perte de données, intrusion, virus, rupture de service, ou autres problèmes involontaires.</p><br />
                <span>10. Droit de rétractation</span>
                <p>Conformément aux dispositions de l’article L. 221-18 du Code de la consommation, le Client dispose à compter de la livraison, d’un délai de 14 jours pour retourner le colis dans son emballage d’origine, non ouvert et dans un parfait état, aux fins d’échange ou de remboursement, les frais de retour étant à la charge du Client.</p><br/>
                <span>11. Propriété intellectuelle</span>
                <p>Tous les éléments du site rucherdesalbizias.fr sont et restent la propriété intellectuelle et exclusive de la société. Personne n’est autorisé à reproduire, exploiter, rediffuser, ou utiliser à quelque titre que ce soit, même partiellement, des éléments du site. Tout lien simple ou par hypertexte est strictement interdit sans l’accord écrit exprès du Vendeur.</p><br />
                <span>12. Données personnelles</span>
                <p>Le Vendeur collecte les données à caractère personnel de ses clients et notamment le nom, le prénom, le domicile, le numéro de téléphone et l’adresse électronique. Il se conforme au Règlement Général sur la Protection des Données Personnelles (RGPD) n°2016/679 et à la Loi Informatique et libertés du 6 janvier 1978. Le Client est informé que la collecte de ses données à caractère personnel par le Vendeur est nécessaire au traitement de sa commande et à l'établissement des factures, notamment. Ces données peuvent être communiquées aux éventuels partenaires du Vendeur chargés de l'exécution, du traitement, de la gestion et du paiement des commandes. Le Client est informé que le Vendeur conservera les données ainsi recueillies aussi longtemps que nécessaire pour l'exécution du contrat et des garanties éventuellement applicables. Conformément à la réglementation applicable, le Client dispose d'un droit d'accès, de rectification, d'effacement, et de portabilité des données le concernant, ainsi que du droit de s'opposer au traitement pour motif légitime, droits qu'il peut exercer en s'adressant au responsable de traitement par email: contact@lerucherdesalbizzias.fr.</p>
                <p>Le Client est par ailleurs informé qu’en cas de refus, il peut introduire une réclamation auprès de la CNIL (3 place de Fontenoy - 75007 PARIS) ou saisir une autorité judiciaire.</p><br/>
                <span>13. Information précontractuelle - Acceptation du Client</span>
                <p>L'information précontractuelle doit porter sur les caractéristiques essentielles du bien ou du service, compte tenu du support de communication utilisé et du bien ou du service concerné (C. consom. art. L 111-1 1°).</p>
                <p>Le fait pour une personne physique (ou morale), de commander sur le site Internet rucherdesalbizias.fr emporte adhésion et acceptation pleine et entière des présentes Conditions Générales de Vente et obligation au paiement des Produits commandés, ce qui est expressément reconnu par le Client, qui renonce, notamment, à se prévaloir de tout document contradictoire, qui serait inopposable au Vendeur.</p>
            </div>
            <div id='siteMap' className='marg info_padd'>
                <h1 className='h1_info'>Plan du site</h1>
                <div className='map_block'>
                    <div className='map_home'>
                        <Link className='link_effect' to='/#home_container'><p>accueil</p></Link>
                    </div>
                    <div className='map_hor'>
                        <div className='horizontal'></div>
                        <div className='horizontal'></div>
                        <div className='horizontal'></div>
                        <div className='horizontal'></div>
                        <div className='horizontal'></div>
                    </div>
                    <div className='map_ways'>
                        <div className='map_way'>
                            <Link className='link_effect' to='/cart#cart_container'><p>panier</p></Link>
                        </div>
                        <div className='map_way'>
                            <Link className='link_effect' to='/user-account#userAccount_container'><p>compte</p></Link>
                        </div>
                        <div className='map_way'>
                            <Link className='link_effect' to='/products#products_container'><p>miels</p></Link>
                        </div>
                        <div className='map_way'>
                            <Link className='link_effect' to='/#footer_container'><p>informations</p></Link>
                        </div>
                        <div className='map_way'>
                            <Link className='link_effect' to='/apiary#apiary_container'><p>le rucher</p></Link>
                        </div>
                    </div>
                    <div className='map_hor'>
                        <div className='horizontal hor_no'></div>
                        <div className='horizontal '></div>
                        <div className='horizontal hor_no'></div>
                        <div className='horizontal '></div>
                        <div className='horizontal hor_no'></div>
                    </div>
                    <div className='map_last'>
                        <div>
                            <Link className='link_effect' to='/login#login_container'><p>connexion</p></Link>
                            <Link className='link_effect' to='/signup#signup_container'><p>inscription</p></Link>
                            <Link className='link_effect' to='/forgotPassword#forgot_container'><p>mot de passe oublié</p></Link>
                        </div>
                        <div>
                            <Link className='link_effect' to='/info#cgv_block'><p></p>C.G.V</Link>
                            <Link className='link_effect' to='/info#leg_block'><p>mentions légales</p></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div id='leg_block' className='info_padd'>
                <h1 className='h1_info'>Mentions légales</h1>
                <span>1. Présentation du site</span>
                <div>
                    <p>En vertu de l'article 19 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site rucherdesalbizias.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
                    <ul style={{padding: '0px'}}>
                        <li>Propriétaire: Le Rucher des Albizias – 978 960 797 R.C.S Pau - siret : 97896079700022</li>
                        <li>Adresse: 15 rue du Pic du Ger 64230 DENGUIN</li>
                        <li>Adresse électronique: contact@lerucherdesalbizias@gmail.com</li>
                    </ul>
                </div><br/>
                <span>2. Conditions générales d’utilisation du site et des services proposés</span>
                <p>L’utilisation du site rucherdesalbizias.fr implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site rucherdesalbizias.fr sont donc invités à les consulter de manière régulière.</p>
                <p>Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par Le Rucher des Albizias, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.</p>                
                <p>Le site rucherdesalbizias.fr est mis à jour régulièrement. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p><br />
                <span>3. Limitations contractuelles sur les données techniques</span>
                <p>Le site utilise la technologie JavaScript.</p>
                <p>Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.</p><br />
                <span>4. Propriété intellectuelle et contrefaçons</span>
                <p>Le Rucher des Albizias est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.</p>
                <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : Le Rucher des Albizias.</p>
                <p>Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p><br />
                <span>5. Limitations de responsabilité</span>
                <p>Le Rucher des Albizias ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site rucherdesalbizias.fr, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.</p>
                <p>Le Rucher des Albizias ne pourra également être tenu responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site rucherdesalbizias.fr.</p><br />
                <span>6. Gestion des données personnelles</span>
                <p>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>
                <p>En tout état de cause Le Rucher des Albizias ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site l’obligation ou non de fournir ces informations.</p>
                <p>Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.</p>
                <p>Aucune information personnelle de l'utilisateur du site n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat du Rucher des Albizias et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site.</p>
                <p>Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p><br />
                <span>7. Cookies</span>
                <p>Ce site ne contient pas de cookie publicitaire ou de mesure d'audience.</p><br />
                <span>8. Droit applicable et attribution de juridiction</span>
                <p>Tout litige en relation avec l’utilisation du site est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.</p><br />
                <span>9. Les principales lois concernées</span>
                <p>Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés.</p>
                <p>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.</p><br />
                <span>10. Lexique</span>
                <p>Utilisateur : Internaute se connectant, utilisant le site susnommé.</p>
                <p>Informations personnelles : « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</p>
            </div>
        </div>
        </> 
    )    
}

export default Info