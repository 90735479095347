// loqique inscription utilisateur
import Form from "../components/Form";
import Breadcrumb from "../components/Breadcrumb";
import '../style/Login.css';

function Signup({ isAuth, setIsAuth }) {
    return (
        <>
        <Breadcrumb 
        dest_name1='Connexion'
        dest_link1='/login'
        dest_name2='Créer un compte'
        style_link2={{fontWeight: 'bold'}}
        gt2={{display: 'none'}}
        />
        <div id="signup_container" className="g_layout logSign_container">
            <h1 className="login_title h1_desk_fontSize">Créer un compte</h1>
            <Form 
            id='signup_form'
            textButton='Enregistrer'
            url={`${process.env.REACT_APP_SERVER_URL}/api/auth/signup`}
            method='POST'
            navUrl='/'
            errorContent='Ce compte est déjà associé à un utilisateur / erreur serveur'
            styleCivility={{display:'none'}}
            styleFirstName={{display:'none'}}
            styleLastName={{display:'none'}}
            styleAddress={{display:'none'}}
		    styleDelivery={{display:'none'}}
            styleConfirm={{display:'none'}}
            styleCity={{display:'none'}}
            stylePostCode={{display:'none'}}
            styleAdditional={{display:'none'}}
            stylePhone={{display:'none'}}
            styleOldPassword={{display:'none'}}
            styleTitle={{display:'none'}}
            requiredEmail={true}
            requiredPassword={true}
            isAuth={isAuth}
            setIsAuth={setIsAuth}
            />
            <div className='separate_block'>
                <div className="separate sep_log"></div>
            </div>
        </div>
        </>
    );
};

export default Signup